import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button as MUIButton,
  Box,
} from "@mui/material";
import {
  Button,
  useGetList,
  Loading,
  useDataProvider,
  useGetOne,
} from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import RestoreIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { MensWc, WomensWc } from "./MensWc";
import { Countries as countries } from "./Countries";
import PomSubsView from "./PomSubsView";
import Parse from "parse";

function MyButton(props) {
  const [count, setCount] = useState(false);

  function handleClick() {
    setCount(!count);
    props.onClick();
  }

  return (
    <Button
      // disabled={count}
      style={{
        ...props.style,
        backgroundColor: count ? "gray" : props.style.backgroundColor,
      }}
      variant="contained"
      size="large"
      onClick={handleClick}
      label={`${props.label}`}
    />
  );
}

const BarPlates = ({ wheight, refreshedPlatform, competition }) => {
  const ref = useRef();
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (ref.current) {
      var totalWeight = parseInt(wheight); /* fixed to kg for now */
      var unit = "Kg"; /* fixed to kg for now */
      var collar = 2.5; /* always in kg */
      let fifthy_pairs = competition.fifthy_pairs;
      let twentyfive_pairs = competition.twentyfive_pairs;
      let twenty_pairs = competition.twenty_pairs;
      let fifteen_pairs = competition.fifteen_pairs;
      let ten_pairs = competition.ten_pairs;
      let five_pairs = competition.five_pairs;
      let twofive_pairs = competition.twofive_pairs;
      let one_dotfive_pairs = competition.one_dotfive_pairs;
      let one_pairs = competition.one_pairs;
      let dotfive_pairs = competition.dotfive_pairs;
      // load canvas
      // var ctx_kg = document.getElementById("w2" + '-cv1').getContext('2d');
      // var ctx_lbs = document.getElementById("w2" + '-cv2').getContext('2d');
      const ctx_kg = ref.current.getContext("2d");
      // clean areas
      barLoadingWidget.clearCanvas(ctx_kg);
      if (totalWeight < 40 || totalWeight > 325) {
        // barLoadingWidget.clearCanvas(ctx_lbs);
        return;
      }

      // create bar
      barLoadingWidget.drawBar(ctx_kg, Math.round(totalWeight) + " kg", 20);
      // create collars
      if (collar > 0) barLoadingWidget.drawCollar(ctx_kg, collar);
      // calculate weights
      // #1: 50kg plates at 235+ and 304+ kg

      var sideWeight = (totalWeight - 20) / 2 - collar;
      var remainingWeight = sideWeight;
      console.log(remainingWeight);
      var plates = new Array();

      if (totalWeight > 235) {
        if (fifthy_pairs > 0) {
          plates.push(50);
          remainingWeight -= 50;
          fifthy_pairs -= 1;
        }
      }
      if (totalWeight > 304) {
        if (fifthy_pairs > 0) {
          plates.push(50);
          remainingWeight -= 50;
          fifthy_pairs -= 1;
        }
      }

      for (var i = twentyfive_pairs; i > 0; i--) {
        if (remainingWeight >= 25) {
          if (twentyfive_pairs > 0) {
            plates.push(25);
            remainingWeight -= 25;
            twentyfive_pairs -= 1;
          }
        }
      }

      for (var i = twenty_pairs; i > 0; i--) {
        if (remainingWeight >= 20) {
          if (twenty_pairs > 0) {
            plates.push(20);
            remainingWeight -= 20;
            twentyfive_pairs -= 1;
          }
        }
      }

      for (var i = fifteen_pairs; i > 0; i--) {
        if (remainingWeight >= 15) {
          if (fifteen_pairs > 0) {
            plates.push(15);
            remainingWeight -= 15;
            fifteen_pairs -= 1;
          }
        }
      }

      for (var i = ten_pairs; i > 0; i--) {
        if (remainingWeight >= 10) {
          if (ten_pairs > 0) {
            plates.push(10);
            remainingWeight -= 10;
            ten_pairs -= 1;
          }
        }
      }

      for (var i = five_pairs; i > 0; i--) {
        if (remainingWeight >= 5) {
          if (five_pairs > 0) {
            plates.push(5);
            remainingWeight -= 5;
            five_pairs -= 1;
          }
        }
      }

      for (var i = twofive_pairs; i > 0; i--) {
        if (remainingWeight >= 2.5) {
          if (twofive_pairs > 0) {
            plates.push(2.5);
            remainingWeight -= 2.5;
            twofive_pairs -= 1;
          }
        }
      }

      for (var i = one_dotfive_pairs; i > 0; i--) {
        if (remainingWeight >= 1.5) {
          if (one_pairs > 0) {
            plates.push(1.5);
            remainingWeight -= 1.5;
            one_pairs -= 1.5;
          }
        }
      }

      for (var i = one_pairs; i > 0; i--) {
        if (remainingWeight >= 1) {
          if (one_pairs > 0) {
            plates.push(1);
            remainingWeight -= 1;
            one_pairs -= 1;
          }
        }
      }

      for (var i = dotfive_pairs; i > 0; i--) {
        if (remainingWeight >= 0.5) {
          if (dotfive_pairs > 0) {
            plates.push(0.5);
            remainingWeight -= 0.5;
            dotfive_pairs -= 1;
          }
        }
      }

      // print plates
      for (var i = plates.length - 1; i >= 0; i--) {
        barLoadingWidget.drawPlate(ctx_kg, plates[i], "kg");
      }
      // show remaining
      if (remainingWeight)
        barLoadingWidget.drawNotes(
          ctx_kg,
          "Missing weight = " + remainingWeight.toFixed(3) + " kg",
        );
    }
  }, [wheight]);

  const barLoadingWidget = {
    x: 0,
    platesKg: {
      0.5: {
        colour: competition.dotfive_color,
        text: "black",
        height: 75,
        width: 20,
      },
      1: {
        colour: competition.one_color,
        text: "black",
        height: 85,
        width: 20,
      },
      1.5: {
        colour: competition.one_dotfive_color,
        text: "black",
        height: 90,
        width: 20,
      },
      2: {
        colour: "#66CCFF",
        text: "black",
        height: 95,
        width: 20,
      },
      2.5: {
        colour: competition.twofive_color,
        text: "white",
        height: 100,
        width: 22,
      },
      5: {
        colour: competition.five_color,
        text: "black",
        height: 180,
        width: 24,
      },
      10: {
        colour: competition.ten_color,
        text: "black",
        height: 180,
        width: 24,
      },
      15: {
        colour: competition.fifteen_color,
        text: "black",
        height: 180,
        width: 24,
      },
      20: {
        colour: competition.twenty_color,
        text: "black",
        height: 180,
        width: 24,
      },
      25: {
        colour: competition.twentyfive_color,
        text: "black",
        height: 180,
        width: 30,
      },
      50: {
        colour: competition.fifthy_color,
        text: "white",
        height: 180,
        width: 30,
      },
    },
    platesLbs: {
      2.5: {
        colour: "gray",
        text: "white",
        height: 75,
        width: 20,
      },
      5: {
        colour: "gray",
        text: "white",
        height: 85,
        width: 22,
      },
      10: {
        colour: "gray",
        text: "white",
        height: 90,
        width: 24,
      },
      25: {
        colour: "gray",
        text: "white",
        height: 95,
        width: 26,
      },
      35: {
        colour: "gray",
        text: "white",
        height: 100,
        width: 26,
      },
      45: {
        colour: "gray",
        text: "white",
        height: 180,
        width: 28,
      },
      100: {
        colour: "gray",
        text: "white",
        height: 180,
        width: 30,
      },
    },
    clearCanvas: function (ctx, totalWeight) {
      ctx.clearRect(0, 0, 300, 300);
      ctx.font = "12px Arial";
      this.x = 234;
    },
    drawBar: function (ctx, totalWeight, barWeight) {
      var barHeight = 24;
      ctx.fillStyle = "silver";
      ctx.fillRect(5, 150 - barHeight / 2, 270, barHeight);
      ctx.fillStyle = "gray";
      ctx.strokeRect(5, 150 - barHeight / 2, 270, barHeight);
      ctx.fillText(barWeight, 30, 154);
      var font = ctx.font;
      ctx.font = "34px Arial";
      ctx.fillStyle = "black";
      ctx.fillText(totalWeight, 110, 34);
      ctx.font = font;
    },
    drawCollar: function (ctx, collarWeight) {
      var collarHeight = 48,
        collarWidth = 24;
      ctx.beginPath();
      ctx.lineWidth = 4;
      ctx.moveTo(this.x + collarWidth / 2, 150 - collarHeight / 2);
      ctx.lineTo(this.x + (3 * collarWidth) / 4, 150 - collarHeight / 2 - 10);
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.fillStyle = "silver";
      ctx.fillRect(this.x, 150 - collarHeight / 2, collarWidth, collarHeight);
      ctx.fillStyle = "gray";
      ctx.strokeRect(this.x, 150 - collarHeight / 2, collarWidth, collarHeight);
      var textWidth = ctx.measureText("2.5").width;
      ctx.fillText(collarWeight, this.x + (collarWidth - textWidth) / 2, 154);
    },
    drawPlate: function (ctx, plate, unit) {
      var plateDefinition =
        unit === "lbs"
          ? this.platesLbs[plate.toString()]
          : this.platesKg[plate.toString()];
      this.x -= plateDefinition.width;
      ctx.fillStyle = plateDefinition.colour;
      ctx.fillRect(
        this.x,
        150 - plateDefinition.height / 2,
        plateDefinition.width,
        plateDefinition.height,
      );
      ctx.fillStyle = "gray";
      ctx.strokeRect(
        this.x,
        150 - plateDefinition.height / 2,
        plateDefinition.width,
        plateDefinition.height,
      );
      var textWidth = ctx.measureText(plate).width;
      ctx.fillStyle = plateDefinition.text;
      ctx.fillText(
        plate,
        this.x + (plateDefinition.width - textWidth) / 2,
        154,
      );
    },
    drawNotes: function (ctx, text) {
      ctx.font = "18px Arial";
      ctx.fillStyle = "gray";
      ctx.fillText(text, 10, 280);
    },
  };

  return <canvas ref={ref} id="w2-cv1" width="280" height="300"></canvas>;
};

const Decision = ({ referee, index }) => (
  <Box
    backgroundColor={
      referee && referee[index] === "bp"
        ? "turquoise"
        : referee && referee[index] === "ds"
          ? "blue"
          : referee && referee[index] === "ss"
            ? "orange"
            : referee && referee[index] === "ps"
              ? "purple"
              : referee && referee[index] === "good"
                ? "#ffffff"
                : "none"
    }
    component="div"
    sx={{
      borderRadius: 2,
      width: index === 0 ? "93%" : "80%",
      height: 50,
      margin: "auto",
    }}
  />
);

export const Referee = (props) => {
  const navigate = useNavigate();
  const user = Parse.User.current();
  const { platform } = useParams();
  const dataProvider = useDataProvider();
  const [round, setRound] = React.useState("1");
  const [decisionState, setDecisionState] = React.useState([]);
  // const [competition, setCompetition] = React.useState("1");

  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      platform,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "fullname", order: "ASC" },
  });

  const [refreshedLifters, setRefreshedLifters] = React.useState(
    lifters || null,
  );

  const {
    data: teams,
    total2,
    isLoading2,
    error2,
  } = useGetList("teams", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const {
    data: record,
    isLoading3,
    error3,
  } = useGetOne("platforms", { id: platform });
  const [refreshedPlatform, setRefreshedPlatform] = React.useState(
    record || null,
  );

  const { data: competition, isLoading4 } = useGetOne("competitions", {
    id: record ? record.competition : "1",
  });

  const livequery = async () => {
    // const self = this;

    if (!refreshedPlatform && platform) {
      const { data: recordT } = await dataProvider.getOne("platforms", {
        id: platform,
      });
      setRefreshedPlatform(recordT);
    }

    const query = new Parse.Query("platforms");
    query.equalTo("objectId", platform);
    const subscription = await query.subscribe();
    subscription.on("update", async (object) => {
      setRound(object.get("round"));
      setRefreshedPlatform(object.toJSON());
    });

    const queryLifters = new Parse.Query("lifters");
    queryLifters.equalTo("platform", platform);
    const subscriptionLifers = await queryLifters.subscribe();
    subscriptionLifers.on("update", async (object) => {
      //Obtengo al current
      const { data: liftersT } = await dataProvider.getList("lifters", {
        filter: {
          platform,
        },
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "fullname", order: "ASC" },
      });
      setRefreshedLifters(liftersT);
    });
  };

  const initialized = useRef(false);
  React.useEffect(() => {
    if (platform) {
      if (!initialized.current) {
        initialized.current = true;
        livequery();
        createDoc(platform);
      }
    }
  }, [platform]);

  const createDoc = async (platform) => {
    if (props.position === "c") {
      const { data: chiefDecision } = await dataProvider.getList(
        "chief_referee_decisions",
        {
          filter: {
            platform,
          },
          pagination: { page: 1, perPage: 10000 },
          sort: { field: "createdAt", order: "ASC" },
        },
      );
      if (!chiefDecision || chiefDecision.length === 0) {
        const newDoc = new Parse.Object("chief_referee_decisions");
        newDoc.set("platform", platform);
        newDoc.set("decisions", []);
        newDoc.save();
      }
    }

    if (props.position === "l") {
      const { data: leftDecision } = await dataProvider.getList(
        "left_referee_decisions",
        {
          filter: {
            platform,
          },
          pagination: { page: 1, perPage: 10000 },
          sort: { field: "createdAt", order: "ASC" },
        },
      );

      if (!leftDecision || leftDecision.length === 0) {
        const newDoc = new Parse.Object("left_referee_decisions");
        newDoc.set("platform", platform);
        newDoc.set("decisions", []);
        newDoc.save();
      }
    }

    if (props.position === "r") {
      const { data: rightDecision } = await dataProvider.getList(
        "right_referee_decisions",
        {
          filter: {
            platform,
          },
          pagination: { page: 1, perPage: 10000 },
          sort: { field: "createdAt", order: "ASC" },
        },
      );

      if (!rightDecision || rightDecision.length === 0) {
        const newDoc = new Parse.Object("right_referee_decisions");
        newDoc.set("platform", platform);
        newDoc.set("decisions", []);
        newDoc.save();
      }
    }
  };

  React.useEffect(() => {
    if (!isLoading && lifters.length && !refreshedLifters)
      setRefreshedLifters(lifters);
  }, [isLoading]);

  function setDesision(desision) {
    let field = null;
    let resource = null;
    if (props.position === "c") {
      resource = "chief_referee_decisions";
    }
    if (props.position === "l") {
      resource = "left_referee_decisions";
    }
    if (props.position === "r") {
      resource = "right_referee_decisions";
    }
    // if (field) {
    let myDesitions = decisionState ? decisionState : [];
    const index = myDesitions.indexOf(desision);
    if (index > -1) {
      // only splice array when item is found
      myDesitions.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      const oldGoodDesitionsIndex = myDesitions.indexOf("good");
      if (oldGoodDesitionsIndex > -1) {
        myDesitions.splice(oldGoodDesitionsIndex, 1);
      }
      if (desision === "good") {
        myDesitions = [desision];
      } else myDesitions.push(desision);
    }

    // console.log(myDesitions);
    setDecisionState(myDesitions);

    const GameScore = Parse.Object.extend(resource);
    const query = new Parse.Query(GameScore);
    query.equalTo("platform", platform);
    query
      .find()
      .then((results) => {
        for (let i = 0; i < results.length; i++) {
          const object = results[i];
          // alert(object.id + ' - ' + object.get('playerName'));
          object.set("decisions", myDesitions);
          object
            .save()
            .then((rslt) => {
              console.log(rslt);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      });
    // Do something with the returned Parse.Object values
  }

  /*

  
  const queryCRef = new Parse.Query(props.position==="c" ? 'chief_referee_decisions' : props.position==="l" ? 'left_referee_decisions' : 'right_referee_decisions');
      queryCRef.equalTo('platform', platform);
      const resultsCRef = await queryCRef.find();
      alert(JSON.stringify(resultsCRef))
      if(!resultsCRef.length){
        console.log("creo uno")
        const myDesitions = [];
        if (index > -1) { // only splice array when item is found
          myDesitions.splice(index, 1); // 2nd parameter means remove one item only
        }else{
          if(desision === "good"){
            myDesitions.pop();
            myDesitions.push(desision)
          }
          else
            myDesitions.push(desision)
        }
        dataProvider.create(props.position==="c" ? 'chief_referee_decisions' : props.position==="l" ? 'left_referee_decisions' : 'right_referee_decisions', { data: { decisions: myDesitions, platform: platform, } });
      }else{
        const myDesitions = resultsCRef[0].get("decisions");
        const index = myDesitions.indexOf(desision);
        if (index > -1) { // only splice array when item is found
          myDesitions.splice(index, 1); // 2nd parameter means remove one item only
        }else{
          if(desision === "good"){
            myDesitions.pop();
            myDesitions.push(desision)
          }
          else
            myDesitions.push(desision)
        }
        dataProvider.update(props.position==="c" ? 'chief_referee_decisions' : props.position==="l" ? 'left_referee_decisions' : 'right_referee_decisions', {id: resultsCRef[0].id, data: { decisions: myDesitions, platform: platform, } });        
      }

  */

  if (isLoading || isLoading2 || isLoading3 || isLoading4 || !competition) {
    return <Loading />;
  }
  if (error || error2 || error3) {
    return <p>ERROR</p>;
  }

  // return (<>{JSON.stringify(record)}</>)

  return (
    <Card>
      <CardHeader
        title={`${props.position === "c" ? "CHIEF REFEREE" : props.position === "l" ? "LEFT REFEREE" : props.position === "r" ? "RIGHT REFEREE" : ""}`}
      />
      <CardContent style={{ backgroundColor: "black", borderRadius: 50 }}>
        {refreshedPlatform && refreshedPlatform.current ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/*<h3>{refreshedPlatform && refreshedPlatform.current && refreshedPlatform.current.fullname}</h3>
            <h3>{teams && teams.length && teams.find(item => item.id === lifters[2].team) ? teams.find(item => item.id === refreshedPlatform.current.team).name : null}</h3>
            <h3>Rack Height: {refreshedPlatform && refreshedPlatform.current.rack_height}</h3>*/}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <MyButton
                    onClick={() => setDesision("bp")}
                    decisionState={decisionState}
                    label="Body position"
                    style={{
                      backgroundColor: "turquoise",
                      height: 50,
                      margin: 5,
                      minWidth: 100,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <MyButton
                    onClick={() => setDesision("ds")}
                    decisionState={decisionState}
                    label="Down Sequence"
                    style={{
                      backgroundColor: "blue",
                      height: 50,
                      margin: 5,
                      minWidth: 100,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <MyButton
                    onClick={() => setDesision("ss")}
                    decisionState={decisionState}
                    label="Stop Sequence"
                    style={{
                      backgroundColor: "orange",
                      height: 50,
                      margin: 5,
                      minWidth: 100,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <MyButton
                    onClick={() => setDesision("ps")}
                    decisionState={decisionState}
                    label="Press Sequence"
                    style={{
                      backgroundColor: "purple",
                      height: 50,
                      margin: 5,
                      minWidth: 100,
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "white",
                  minWidth: 100,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <MyButton
                    onClick={() => setDesision("good")}
                    label="Good Lift"
                    decisionState={decisionState}
                    size="large"
                    style={{
                      backgroundColor: "white",
                      height: 50,
                      margin: 5,
                      minWidth: 100,
                    }}
                  />
                </div>
                <h3 style={{ margin: 0, fontSize: 18 }}>
                  <span style={{ color: "#008000", fontSize: 40 }}>
                    {refreshedPlatform &&
                      refreshedPlatform.current &&
                      refreshedPlatform.current.lot_number}
                    .{" "}
                  </span>
                  {refreshedPlatform &&
                    refreshedPlatform.current &&
                    refreshedPlatform.current.fullname}
                  <br />
                  <span style={{ color: "#008000", fontSize: 10 }}>
                    {" "}
                    Exceptions codes:{" "}
                    {refreshedPlatform &&
                      refreshedPlatform.current &&
                      refreshedPlatform.current.exceptions}
                  </span>
                </h3>
                {refreshedLifters &&
                  refreshedPlatform &&
                  refreshedLifters.find(
                    (lifter) => lifter.id === refreshedPlatform.current.id,
                  ) && (
                    <h3 style={{ fontSize: 14, margin: 0 }}>
                      {round === "1"
                        ? refreshedLifters.find(
                            (lifter) =>
                              lifter.id === refreshedPlatform.current.id,
                          )["first_attempt"]
                        : round === "2"
                          ? refreshedLifters.find(
                              (lifter) =>
                                lifter.id === refreshedPlatform.current.id,
                            )["second_attempt"]
                          : round === "3"
                            ? refreshedLifters.find(
                                (lifter) =>
                                  lifter.id === refreshedPlatform.current.id,
                              )["third_attempt"]
                            : null}{" "}
                      Kg
                    </h3>
                  )}
                <h3 style={{ margin: 0, fontSize: 18 }}>
                  Round {refreshedPlatform.round}
                  {" , "}
                  {refreshedPlatform &&
                  [...MensWc, ...WomensWc].find(
                    (item) =>
                      item.id === refreshedPlatform.current.weight_class,
                  )
                    ? [...MensWc, ...WomensWc].find(
                        (item) =>
                          item.id === refreshedPlatform.current.weight_class,
                      ).name
                    : null}
                </h3>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3>
                    {refreshedPlatform &&
                    teams &&
                    teams.length &&
                    teams.find(
                      (item) => item.id === refreshedPlatform.current.team,
                    )
                      ? teams.find(
                          (item) => item.id === refreshedPlatform.current.team,
                        ).name
                      : null}
                  </h3>
                  {refreshedPlatform &&
                    countries.find(
                      (ctry) => ctry.code === refreshedPlatform.current.country,
                    ) &&
                    countries.find(
                      (ctry) => ctry.code === refreshedPlatform.current.country,
                    ).flag && (
                      <img
                        src={`/flags/${countries.find((ctry) => ctry.code === refreshedPlatform.current.country).flag}`}
                        loading="lazy"
                        width="40"
                        // src={`https://flagcdn.com/w20/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png`}
                        // srcSet={`https://flagcdn.com/w40/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png 2x`}
                        alt=""
                      />
                    )}
                </div>
              </div>
            </Grid>
          </Grid>
        ) : null}
        {refreshedPlatform && (
          <div
            style={{
              display: "flex",
              border: "1px solid grey",
              padding: 5,
              borderRadius: 8,
            }}
          >
            <Grid container spacing={0}>
              {/* <Grid item xs={1} style={{ marginBottom: "5px" }}> */}
              {/*   <Decision */}
              {/*     referee={ */}
              {/*       props.position === "l" */}
              {/*         ? refreshedPlatform.lr */}
              {/*         : props.position === "c" */}
              {/*           ? refreshedPlatform.hr */}
              {/*           : props.position === "r" */}
              {/*             ? refreshedPlatform.rr */}
              {/*             : refreshedPlatform.hr */}
              {/*     } */}
              {/*     index={0} */}
              {/*   /> */}
              {/* </Grid> */}
              {/* <Grid item xs={1}> */}
              {/*   <Decision */}
              {/*     referee={ */}
              {/*       props.position === "l" */}
              {/*         ? refreshedPlatform.lr */}
              {/*         : props.position === "c" */}
              {/*           ? refreshedPlatform.hr */}
              {/*           : props.position === "r" */}
              {/*             ? refreshedPlatform.rr */}
              {/*             : refreshedPlatform.hr */}
              {/*     } */}
              {/*     index={1} */}
              {/*   /> */}
              {/* </Grid> */}
              {/* <Grid item xs={1}> */}
              {/*   <Decision */}
              {/*     referee={ */}
              {/*       props.position === "l" */}
              {/*         ? refreshedPlatform.lr */}
              {/*         : props.position === "c" */}
              {/*           ? refreshedPlatform.hr */}
              {/*           : props.position === "r" */}
              {/*             ? refreshedPlatform.rr */}
              {/*             : refreshedPlatform.hr */}
              {/*     } */}
              {/*     index={2} */}
              {/*   /> */}
              {/* </Grid> */}
              {/* <Grid item xs={1}> */}
              {/*   <Decision */}
              {/*     referee={ */}
              {/*       props.position === "l" */}
              {/*         ? refreshedPlatform.lr */}
              {/*         : props.position === "c" */}
              {/*           ? refreshedPlatform.hr */}
              {/*           : props.position === "r" */}
              {/*             ? refreshedPlatform.rr */}
              {/*             : refreshedPlatform.hr */}
              {/*     } */}
              {/*     index={3} */}
              {/*   /> */}
              {/* </Grid> */}

              <Grid item xs={8} style={{ color: "white", textAlign: "center" }}>
                {competition &&
                  refreshedLifters &&
                  refreshedPlatform &&
                  refreshedPlatform.current &&
                  refreshedLifters.find(
                    (lifter) => lifter.id === refreshedPlatform.current.id,
                  ) && (
                    <BarPlates
                      competition={competition}
                      refreshedPlatform={refreshedPlatform}
                      wheight={
                        refreshedPlatform &&
                        refreshedPlatform.round === "1" &&
                        refreshedLifters
                          ? refreshedLifters.find(
                              (lifter) =>
                                lifter.id === refreshedPlatform.current.id,
                            )["first_attempt"]
                          : refreshedPlatform &&
                              refreshedPlatform.round === "2" &&
                              refreshedLifters
                            ? refreshedLifters.find(
                                (lifter) =>
                                  lifter.id === refreshedPlatform.current.id,
                              )["second_attempt"]
                            : refreshedPlatform &&
                                refreshedPlatform.round === "3" &&
                                refreshedLifters
                              ? refreshedLifters.find(
                                  (lifter) =>
                                    lifter.id === refreshedPlatform.current.id,
                                )["third_attempt"]
                              : null
                      }
                    />
                  )}
              </Grid>
            </Grid>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
