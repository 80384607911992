export const MensWc = [
    { id: "49", name: "Men's Up to 49 kg" },
    { id: "54", name: "Men's Up to 54 kg" },
    { id: "59", name: "Men's Up to 59 kg" },
    { id: "65", name: "Men's Up to 65 kg" },
    { id: "72", name: "Men's Up to 72 kg" },
    { id: "80", name: "Men's Up to 80 kg" },
    { id: "88", name: "Men's Up to 88 kg" },
    { id: "97", name: "Men's Up to 97 kg" },
    { id: "107", name: "Men's Up to 107 kg" },
    { id: "+107", name: "Men's Over 107 kg" },
];

export const WomensWc = [
    { id: "41", name: "Women's Up to 41 kg" },
    { id: "45", name: "Women's Up to 45 kg" },
    { id: "50", name: "Women's Up to 50 kg" },
    { id: "55", name: "Women's Up to 55 kg" },
    { id: "61", name: "Women's Up to 61 kg" },
    { id: "67", name: "Women's Up to 67 kg" },
    { id: "73", name: "Women's Up to 73 kg" },
    { id: "79", name: "Women's Up to 79 kg" },
    { id: "86", name: "Women's Up to 86 kg" },
    { id: "+86", name: "Women's Over 86 kg" },
];
