import * as React from "react";
import { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  SelectInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  DeleteButton,
  Toolbar,
  TopToolbar,
  useDataProvider,
  useRefresh,
  CreateButton,
  AutocompleteInput,
  FilterButton,
  NumberInput,
  DateInput,
  useListContext,
  Loading,
  useGetList,
  Pagination,
  SimpleList,
  useGetOne,
  SelectArrayInput,
  FunctionField,
  WithListContext,
  useTranslate,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useController } from "react-hook-form";
import IconUpload from "@mui/icons-material/CloudUpload";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { MensWc, WomensWc } from "./MensWc";
import { useCreate, useCreateSuggestionContext } from "react-admin";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as MUITextField,
  Autocomplete as MUIAutocomplete,
  MenuItem,
  Box,
} from "@mui/material";
import readXlsxFile from "read-excel-file";
import { Countries as countries } from "./Countries";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import Papa from "papaparse";

const StyledBox = styled(Box)(({ theme }) => ({
  height: 300,
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const countryRenderer = (option) => `${option.label} (${option.code})`;

const fields = [
  {
    // Visible in table header and when matching columns.
    label: "Name",
    // This is the key used for this field when we call onSubmit.
    key: "fullname",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["first name", "fullname", "NOME", "nome"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stephanie Doe",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Name is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    // Visible in table header and when matching columns.
    label: "Exception Codes",
    // This is the key used for this field when we call onSubmit.
    key: "exceptions",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["exceptions", "ec", "exception codes"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "e g l",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "SDMS",
    // This is the key used for this field when we call onSubmit.
    key: "sdmsid",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["sdmsid", "smdms", "SDMS"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "111111",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Club",
    // This is the key used for this field when we call onSubmit.
    key: "team",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["time", "clube", "CLUBE", "club"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Eagles",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Sex",
    // This is the key used for this field when we call onSubmit.
    key: "sex",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["gender", "genero", "GÊNERO", "Gender"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "m",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Date of birth",
    // This is the key used for this field when we call onSubmit.
    key: "date_of_birth",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["dob", "DATA NASCIMENTO", "DoB"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "12/04/1989",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Weight class",
    // This is the key used for this field when we call onSubmit.
    key: "weight_class",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["category", "Event Name", "PROVA"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Women's Up to 41 kg",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Age Division",
    // This is the key used for this field when we call onSubmit.
    key: "divisions",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["Elite"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Elite",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Country",
    // This is the key used for this field when we call onSubmit.
    key: "country",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["pais", "PAIS"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "BRA",
    // Can have multiple validations that are visible in Validation Step table.
  },
];

const Archivo2 = (props) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSubmit={async (data, file) => {
          const rows = data.all ? data.all : [];
          console.log(rows);
          console.log(file);

          const teams = data.all.map((row) => row.team);
          var uniqueTeams = teams.filter(
            (value, index, array) => array.indexOf(value) === index,
          );
          console.log(uniqueTeams);
          const createdTeams = [];
          for (var i = 0; i < uniqueTeams.length; i++) {
            const value = uniqueTeams[i];
            const { data } = await dataProvider.create("teams", {
              data: {
                name: value,
                competition: localStorage.getItem("currentCompetition"),
              },
            });
            console.log(data);
            createdTeams.push(data);
          }

          if (rows)
            for (let i = rows.length - 1; i >= 0; i--) {
              console.log(rows[i]);
              const row = rows[i];
              // var parts = row.date_of_birth.split('/');
              // console.log(parts)
              // const bdate = new Date(parts[0], parseInt(parts[1])-1, parts[2]);
              let weightClass = "";
              if (row.sex === "MASCULINO")
                weightClass = row.weight_class.includes("+107")
                  ? "+107"
                  : MensWc.find((item) => item.name === row.weight_class) &&
                      MensWc.find((item) => item.name === row.weight_class).id
                    ? MensWc.find((item) => item.name === row.weight_class).id
                    : MensWc.find((item) =>
                          row.weight_class.includes(item.id),
                        ) &&
                        MensWc.find((item) =>
                          row.weight_class.includes(item.id),
                        ).id
                      ? MensWc.find((item) =>
                          row.weight_class.includes(item.id),
                        ).id
                      : row.weight_class;

              if (row.sex === "FEMININO")
                weightClass = row.weight_class.includes("+86")
                  ? "+86"
                  : WomensWc.find((item) => item.name === row.weight_class) &&
                      WomensWc.find((item) => item.name === row.weight_class).id
                    ? WomensWc.find((item) => item.name === row.weight_class).id
                    : WomensWc.find((item) =>
                          row.weight_class.includes(item.id),
                        ) &&
                        WomensWc.find((item) =>
                          row.weight_class.includes(item.id),
                        ).id
                      ? WomensWc.find((item) =>
                          row.weight_class.includes(item.id),
                        ).id
                      : row.weight_class;

              // console.log(bdate);
              // console.log(row)
              const lifter = {
                competition: localStorage.getItem("currentCompetition"),
                ...row,
                team: createdTeams.find((item) => item.name === row.team)
                  ? createdTeams.find((item) => item.name === row.team).id
                  : "",
                sex: row.sex === "MASCULINO" ? "m" : "FEMININO" ? "f" : row.sex,
                date_of_birth: row.date_of_birth,
                weight_class: weightClass,
                sdmsid: parseInt(row.sdmsid),
                divisions: ["Elite"],
              };

              delete lifter.__index;

              if (!lifter.sdmsid) delete lifter.sdmsid;
              //Lo busco primero por sdmsid?
              console.log(lifter);
              const { data } = await dataProvider.create("lifters", {
                data: lifter,
              });
              console.log(data);
              refresh();
            }
        }}
        fields={fields}
      />
      <Button onClick={() => setOpen(true)}>
        <IconUpload /> Import{" "}
      </Button>
    </>
  );

  const handleNvEnter = async (event) => {
    console.log(event);
    return;
    const input = document.getElementById("input");
    readXlsxFile(input.files[0]).then(async (rows) => {
      let contador = 0;
      const teams = rows.map((row) => row[3]);
      var uniqueTeams = teams.filter(
        (value, index, array) => array.indexOf(value) === index,
      );
      console.log(uniqueTeams);
      const createdTeams = [];
      for (var i = 0; i < uniqueTeams.length; i++) {
        const value = uniqueTeams[i];
        console.log(value);
        const { data } = await dataProvider.create("teams", {
          data: {
            name: value,
            competition: localStorage.getItem("currentCompetition"),
          },
        });
        console.log(data);
        createdTeams.push(data);
      }
      console.log(createdTeams);

      for (var j = 1; j < rows.length; j++) {
        var row = rows[j];
        var parts = row[6].split("/");
        console.log(parts);
        const bdate = new Date(parts[0], parseInt(parts[1]) - 1, parts[2]);
        let weightClass = "";
        if (row[7] === "MASCULINO")
          weightClass =
            MensWc.find((item) => item.name === row[9]) &&
            MensWc.find((item) => item.name === row[9]).id
              ? MensWc.find((item) => item.name === row[9]).id
              : row[9];

        if (row[7] === "FEMININO")
          weightClass =
            WomensWc.find((item) => item.name === row[9]) &&
            WomensWc.find((item) => item.name === row[9]).id
              ? WomensWc.find((item) => item.name === row[9]).id
              : row[9];

        console.log(bdate);
        console.log(row);

        //Lo busco primero por sdmsid?

        const { data } = await dataProvider.create("lifters", {
          data: {
            competition: localStorage.getItem("currentCompetition"),
            fullname: row[1], //row[8] + ' ' + row[9],
            first_name: row[1],
            last_name: row[1],
            sex: row[5] === "MASCULINO" ? "m" : "FEMININO" ? "f" : row[7],
            country: "BRA",
            // region: row[6],
            sdmsid: row[2] ? row[2] : "",
            date_of_birth: row[6],
            weight_class: weightClass,
            divisions: ["Elite"],
            team: createdTeams.find((item) => item.name === row[3])
              ? createdTeams.find((item) => item.name === row[3]).id
              : "",
          },
        });
        console.log(data);
        refresh();
      }
    });
    // Papa.parse(event.target.files[0], {
    //   header: true,
    //   skipEmptyLines: true,
    //   complete: async function (results) {
    //     // console.log(results.data)
    //     for (var j = 0; j < results.data.length; j++) {
    //       var row = results.data[j];
    //       console.log(row)

    //       const {data} = await dataProvider.create(
    //         'lifters',
    //         { data: {
    //             competition: localStorage.getItem('currentCompetition'),
    //             fullname: row.name,
    //             first_name: row.name,
    //             last_name: row.name,
    //             sex: row.gender === "Male" ? 'm' : "Female" ? 'f' : row.gender,
    //             country: row.team,
    //             // date_of_birth: bdate.toISOString().split('T')[0],
    //             weight_class: row.declaredAwardsWeightClass,
    //             divisions: [row.division],
    //           }}
    //       );
    //       console.log(data);
    //       if(j === results.data.length - 1) {refresh()}
    //     }
    //   },
    // });
  };

  return (
    <label className="custom-file-upload">
      <input onChange={handleNvEnter} type="file" />
      {/*<Typography color="primary" style={{cursor:'pointer'}} align="center"><IconUpload /> Import (Liftingcast File)</Typography>*/}
    </label>
  );
};

const ListActions = (props) => (
  <TopToolbar>
    <Button onClick={props.generateLot}>Generate Lot Numbers</Button>
    <FilterButton />
    <CreateButton variant="contained" />
    <Archivo2 />
    {/* <ExportButton maxResults={50000}/> */}
  </TopToolbar>
);

const Empty = () => (
  <Box style={{ flex: 1 }} textAlign="center" m={1}>
    <Typography variant="h4" paragraph>
      No lifters available
    </Typography>
    <Typography variant="body1">Create one or import from a file</Typography>
    <CreateButton />
    {/*<Archivo />*/}
    <Archivo2 />
  </Box>
);

export const LifterList = () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [isLoadingFlag, setIsLoadingFlag] = React.useState(false);
  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    }
  }, []);

  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "last_name", order: "ASC" },
  });

  const {
    data: platforms,
    total2,
    isLoading2,
    error2,
  } = useGetList("platforms", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const postFilters = [
    <TextInput source="lot_number" alwaysOn />,
    <TextInput source="fullname" alwaysOn />,
    <SelectInput
      source="weight_class"
      choices={[...MensWc, ...WomensWc]}
      optionText="name"
      alwaysOn
    />,
    <SelectInput
      source="divisions"
      choices={[
        { id: "Elite", name: "Elite" },
        { id: "Rookie", name: "Rookie" },
        { id: "Next Gen", name: "Next Gen" },
        { id: "Legend", name: "Legend" },
      ]}
      alwaysOn
    />,
    <SelectInput
      source="sex"
      label="Gender"
      choices={[
        { id: "f", name: "F" },
        { id: "m", name: "M" },
      ]}
      alwaysOn
    />,
    <ReferenceInput
      alwaysOn
      filter={{ competition: localStorage.getItem("currentCompetition") }}
      label="Session"
      source="platform"
      reference="platforms"
    >
      <SelectInput label="Session" optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      perPage={1000000}
      filter={{ competition: localStorage.getItem("currentCompetition") }}
      source="team"
      reference="teams"
      allwaysOn
    >
      <SelectInput label="Team/Club" optionText="name" />
    </ReferenceInput>,
    <SelectInput
      allwaysOn
      source="country"
      // choices={[
      //         { id: 'JPN', name: 'JPN' },
      //         { id: 'IRL', name: 'IRL' },
      //       ]}
      //choices={countries}
      choices={
        lifters
          ? lifters
              .filter((lifter) => lifter.country && lifter.country !== "")
              .map((lifter) => {
                return { id: lifter.country, name: lifter.country };
              })
              .filter((value, index, obj) => {
                const _value = JSON.stringify(value);
                return (
                  index ===
                  obj.findIndex((obj) => {
                    return JSON.stringify(obj) === _value;
                  })
                );
              })
          : []
      }
      // optionText={countryRenderer}
    />,
  ];

  const changeStock = (record, stock, keyProp) => {
    dataProvider.update("lifters", {
      id: record.id,
      data: { [keyProp]: stock },
    });
  };

  const generateLot = async () => {
    if (window.confirm(translate("resources.lifters.confirm_message"))) {
      setIsLoadingFlag(true);
      for (let i = 0; i < lifters.length; i++) {
        try {
          const result = await dataProvider.update("lifters", {
            id: lifters[i].id,
            data: { lot_number: `${i + 1}` },
          });
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      }
      setIsLoadingFlag(false);
      alert(translate("resources.lifters.success_message"));
      window.location.reload();
    }
  };

  if (isLoading2 || isLoading || isLoadingFlag) return <Loading />;

  return (
    <>
      <List
        filter={{
          competition: localStorage.getItem("currentCompetition")
            ? localStorage.getItem("currentCompetition")
            : null,
        }}
        actions={<ListActions generateLot={generateLot} />}
        empty={<Empty />}
        filters={postFilters}
      >
        <Box m={1} style={{ textAlign: "center" }}>
          <strong>{lifters.length} Athletes on the competition</strong>
        </Box>
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record.fullname}
            secondaryText={(record) =>
              [...MensWc, ...WomensWc].find(
                (item) => item.id === record.weight_class,
              )
                ? [...MensWc, ...WomensWc].find(
                    (item) => item.id === record.weight_class,
                  ).name
                : null
            }
            tertiaryText={(record) => record.bodyweight}
          />
        ) : (
          <Datagrid size="small" bulkActionButtons={false}>
            <FunctionField
              label="Lot No."
              render={(record) => (
                <EditableText
                  record={record}
                  resource="lot_number"
                  changeStock={changeStock}
                  style={{ width: 60 }}
                />
              )}
            />
            <FunctionField
              label="Fullname"
              render={(record) => (
                <EditableText
                  record={record}
                  resource="fullname"
                  changeStock={changeStock}
                  style={{ width: 360 }}
                />
              )}
            />
            <FunctionField
              label="SDMS"
              render={(record) => (
                <EditableText
                  record={record}
                  resource="sdmsid"
                  changeStock={changeStock}
                  style={{ width: 80 }}
                />
              )}
            />
            <FunctionField
              label="Gender"
              render={(record) => (
                <EditableSelect
                  record={record}
                  resource="sex"
                  changeStock={changeStock}
                  style={{ width: 60 }}
                  options={[
                    { id: "m", name: "M" },
                    { id: "f", name: "F" },
                  ]}
                />
              )}
            />
            <FunctionField
              label="Weight Class"
              render={(record) => (
                <EditableSelect
                  record={record}
                  resource="weight_class"
                  changeStock={changeStock}
                  style={{ width: 360 }}
                  options={
                    record.sex === "m"
                      ? MensWc
                      : record.sex === "f"
                        ? WomensWc
                        : [...MensWc, ...WomensWc]
                  }
                />
              )}
            />
            <FunctionField
              label="BW"
              render={(record) => (
                <BWEditableText changeStock={changeStock} record={record} />
              )}
            />
            <FunctionField
              label="Rack height"
              render={(record) => (
                <MUITextField
                  type="number"
                  label="Rack height"
                  defaultValue={record.rack_height}
                  onChange={(e) =>
                    changeStock(record, parseInt(e.target.value), "rack_height")
                  }
                  style={{ width: 60 }}
                  size="small"
                />
              )}
            />
            <FunctionField
              label="1st Att"
              render={(record) => (
                <MUITextField
                  type="number"
                  label="1st Att"
                  defaultValue={record.first_attempt}
                  onChange={(e) =>
                    changeStock(
                      record,
                      parseFloat(e.target.value),
                      "first_attempt",
                    )
                  }
                  style={{ width: 60 }}
                  size="small"
                />
              )}
            />
            <FunctionField
              label="Session"
              render={(record) => (
                <EditableSelect
                  record={record}
                  resource="platform"
                  changeStock={changeStock}
                  style={{ width: 200 }}
                  options={
                    !isLoading2 && !error2 && platforms && platforms.length
                      ? platforms
                      : []
                  }
                />
              )}
            />
            <TextField source="date_of_birth" label="DoB" />
            <FunctionField
              label="Age Group"
              render={(record) => (
                <span>{record.divisions}</span>
                // <Select
                //   value={record.divisions}
                //   onChange={(e) =>
                //     changeStock(record, e.target.value, "divisions")
                //   }
                //   style={{ width: 200 }}
                //   size="small"
                // >
                //   <MenuItem>
                //     <i>Select</i>
                //   </MenuItem>
                //   {["Elite", "Rookie", "Next Gen", "Legend"].map((item) => (
                //     <MenuItem value={item}>{item}</MenuItem>
                //   ))}
                // </Select>
              )}
            />
            <EditButton />
          </Datagrid>
        )}
      </List>
      {/*<List
      // filter={{ is_published: "true" }} 
      // perPage={1000} pagination={<Pagination rowsPerPageOptions={[1000]} />}
    perPage={1000000}
          pagination={null}
          exporter={false}
      filter={{ competition: localStorage.getItem("currentCompetition") ? localStorage.getItem("currentCompetition") : null }} 
      actions={<ListActions/>} 
      empty={<Empty />} 
      filters={postFilters} >
      {isSmall ? (
          <SimpleList
              primaryText={(record) => record.fullname}
              secondaryText={(record) => [...MensWc, ...WomensWc].find(item => item.id===record.weight_class) ? [...MensWc, ...WomensWc].find(item => item.id===record.weight_class).name : null}
              tertiaryText={(record) => record.bodyweight}
          />
            ) : (
          <LiftersTable />
        )}
    </List>*/}
    </>
  );
};

const BWEditableText = ({ record, changeStock }) => {
  const [invalidWc2, setInvalidWc2] = useState(false);
  return (
    <>
      <MUITextField
        label="BW"
        defaultValue={record.bodyweight}
        onChange={(e) => {
          const bodyweight = e.target.value;
          if (bodyweight && record.weight_class && record.sex === "m") {
            const prev = MensWc.findIndex(
              (item) => item.id === record.weight_class,
            );
            const prevWC =
              parseInt(prev) > 0 ? MensWc[parseInt(prev) - 1]["id"] : -1;
            if (bodyweight && parseInt(bodyweight) > record.weight_class) {
              setInvalidWc2(true);
            } else if (
              prevWC > -1 &&
              bodyweight &&
              parseInt(bodyweight) <= prevWC
            ) {
              setInvalidWc2(true);
            } else {
              setInvalidWc2(false);
            }
          }

          if (bodyweight && record.weight_class && record.sex === "f") {
            const prev = WomensWc.findIndex(
              (item) => item.id === record.weight_class,
            );
            const prevWC =
              parseInt(prev) > 0 ? MensWc[parseInt(prev) - 1]["id"] : -1;
            if (bodyweight && parseInt(bodyweight) > record.weight_class) {
              setInvalidWc2(true);
            } else if (
              prevWC > -1 &&
              bodyweight &&
              parseInt(bodyweight) <= prevWC
            ) {
              setInvalidWc2(true);
            } else {
              setInvalidWc2(false);
            }
          }
          changeStock(record, e.target.value, "bodyweight");
        }}
        style={{ width: 60 }}
        size="small"
        error={invalidWc2}
        helperText={invalidWc2 ? "Invalid" : ""}
      />
    </>
  );
};

const EditableText = ({ record, resource, changeStock, style }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(record[resource]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setText(event.target.value);
    changeStock(record, event.target.value, resource);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // Save the changes or perform any required actions here
  };

  return (
    <div onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <MUITextField
          defaultValue={record[resource]}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyUp={(e) => e.key === "Enter" && handleBlur()}
          style={style}
          size="small"
        />
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

const EditableSelect = ({ record, resource, changeStock, style, options }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(
    null,
    // record[resource],
  );
  const [value, setValue] = useState(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setText(options.find((item) => item.id === event.target.value).name);
    setValue(event.target.value);
    changeStock(record, event.target.value, resource);
    setIsEditing(false);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // Save the changes or perform any required actions here
  };

  return (
    <div onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <Select
          value={
            options.find((item) => item.id === record[resource])
              ? options.find((item) => item.id === record[resource]).id
              : value
          }
          onChange={handleChange}
          onBlur={handleBlur}
          style={style}
          size="small"
        >
          <MenuItem>
            <i>Select</i>
          </MenuItem>
          {options.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <span>
          {text
            ? text
            : !record[resource]
              ? `Select`
              : options.find((item) => item.id === record[resource])
                ? options.find((item) => item.id === record[resource]).name
                : `Select`}
        </span>
      )}
    </div>
  );
};

export const LifterEdit = () => (
  <Edit actions={<CreateActions />} redirect="list">
    <DetailsForm />
  </Edit>
);

const CreateActions = () => (
  <TopToolbar>
    {/* Add your custom actions */}
    <Button color="primary" onClick={() => window.history.back()}>
      Cancel
    </Button>
  </TopToolbar>
);

export const LifterCreate = () => (
  <Create actions={<CreateActions />} redirect="list">
    <DetailsForm />
  </Create>
);

const DetailsForm = () => {
  const [invalidWc, setInvalidWc] = React.useState(false);

  const {
    data: competition,
    isLoading,
    error,
  } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition"),
  });
  // console.log(competition);
  const divisiones =
    competition && competition.divisions ? [...competition.divisions] : [];
  if (isLoading || !competition) {
    return <Loading />;
  }
  if (error) {
    return <p>{JSON.stringify(error)}</p>;
  }
  return (
    <SimpleForm>
      <TextInput
        label="Event"
        disabled
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <TextInput fullWidth source="fullname" />
      {/*<TextInput fullWidth source="first_name" />
      <TextInput fullWidth source="last_name" />*/}
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} sx={{ display: "flex", flexDirection: "column" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <TextInput
                onChange={(e) => {
                  const sex = formData.sex;
                  const bodyweight = e.target.value;
                  if (bodyweight && formData.weight_class && sex === "m") {
                    const prev = MensWc.findIndex(
                      (item) => item.id === formData.weight_class,
                    );
                    const prevWC =
                      parseInt(prev) > 0
                        ? MensWc[parseInt(prev) - 1]["id"]
                        : -1;
                    if (
                      bodyweight &&
                      parseInt(bodyweight) > formData.weight_class
                    ) {
                      setInvalidWc(true);
                    } else if (
                      prevWC > -1 &&
                      bodyweight &&
                      parseInt(bodyweight) <= prevWC
                    ) {
                      setInvalidWc(true);
                    } else {
                      setInvalidWc(false);
                    }
                  }

                  if (bodyweight && formData.weight_class && sex === "f") {
                    const prev = WomensWc.findIndex(
                      (item) => item.id === formData.weight_class,
                    );
                    const prevWC =
                      parseInt(prev) > 0
                        ? MensWc[parseInt(prev) - 1]["id"]
                        : -1;
                    if (
                      bodyweight &&
                      parseInt(bodyweight) > formData.weight_class
                    ) {
                      setInvalidWc(true);
                    } else if (
                      prevWC > -1 &&
                      bodyweight &&
                      parseInt(bodyweight) <= prevWC
                    ) {
                      setInvalidWc(true);
                    } else {
                      setInvalidWc(false);
                    }
                  }
                }}
                label="Bodyweight Category(ies)"
                source="bodyweight"
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <NumberInput source="first_attempt" />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextInput source="date_of_birth" />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextInput source="lot_number" />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextInput source="exceptions" />
        </Box>
      </Box>
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} sx={{ display: "flex", flexDirection: "column" }}>
          <SelectArrayInput
            source="divisions"
            label="Age Groups"
            choices={divisiones.map((div) => ({ id: div, name: div }))}
          />
        </Box>
        <Box
          flex={0.5}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <SelectInput
                label="Gender"
                source="sex"
                onChange={(e) => {
                  const sex = e.target.value;
                  if (sex === "m") {
                    const prev = MensWc.findIndex(
                      (item) => item.id === formData.weight_class,
                    );
                    const prevWC =
                      parseInt(prev) > 0
                        ? MensWc[parseInt(prev) - 1]["id"]
                        : -1;
                    if (
                      formData.bodyweight &&
                      parseInt(formData.bodyweight) > formData.weight_class
                    ) {
                      setInvalidWc(true);
                    } else if (
                      prevWC > -1 &&
                      formData.bodyweight &&
                      parseInt(formData.bodyweight) <= prevWC
                    ) {
                      setInvalidWc(true);
                    } else {
                      setInvalidWc(false);
                    }
                  }

                  if (sex === "f") {
                    const prev = WomensWc.findIndex(
                      (item) => item.id === formData.weight_class,
                    );
                    const prevWC =
                      parseInt(prev) > 0
                        ? MensWc[parseInt(prev) - 1]["id"]
                        : -1;
                    if (
                      formData.bodyweight &&
                      parseInt(formData.bodyweight) > formData.weight_class
                    ) {
                      setInvalidWc(true);
                    } else if (
                      prevWC > -1 &&
                      formData.bodyweight &&
                      parseInt(formData.bodyweight) <= prevWC
                    ) {
                      setInvalidWc(true);
                    } else {
                      setInvalidWc(false);
                    }
                  }
                }}
                choices={[
                  { id: "f", name: "F" },
                  { id: "m", name: "M" },
                ]}
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData.sex === "m" ? (
                <>
                  <SelectInput
                    fullWidth
                    onChange={(e) => {
                      const prev = MensWc.findIndex(
                        (item) => item.id === e.target.value,
                      );
                      const prevWC =
                        parseInt(prev) > 0
                          ? MensWc[parseInt(prev) - 1]["id"]
                          : -1;
                      if (
                        formData.bodyweight &&
                        parseInt(formData.bodyweight) > e.target.value
                      ) {
                        setInvalidWc(true);
                      } else if (
                        prevWC > -1 &&
                        formData.bodyweight &&
                        parseInt(formData.bodyweight) <= prevWC
                      ) {
                        setInvalidWc(true);
                      } else {
                        setInvalidWc(false);
                      }
                    }}
                    label="Men's Weight Classes"
                    source="weight_class"
                    choices={MensWc}
                  />
                  {invalidWc ? <CloseIcon sx={{ color: "red" }} /> : null}
                </>
              ) : formData.sex === "f" ? (
                <>
                  <SelectInput
                    fullWidth
                    onChange={(e) => {
                      const prev = WomensWc.findIndex(
                        (item) => item.id === e.target.value,
                      );
                      const prevWC =
                        parseInt(prev) > 0
                          ? WomensWc[parseInt(prev) - 1]["id"]
                          : -1;
                      if (
                        formData.bodyweight &&
                        parseInt(formData.bodyweight) > e.target.value
                      ) {
                        setInvalidWc(true);
                      } else if (
                        prevWC > -1 &&
                        formData.bodyweight &&
                        parseInt(formData.bodyweight) <= prevWC
                      ) {
                        setInvalidWc(true);
                      } else {
                        setInvalidWc(false);
                      }
                    }}
                    label="Women's Weight Classes"
                    source="weight_class"
                    choices={WomensWc}
                  />
                  {invalidWc ? <CloseIcon sx={{ color: "red" }} /> : null}
                </>
              ) : null;
            }}
          </FormDataConsumer>
        </Box>
      </Box>
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} sx={{ display: "flex", flexDirection: "column" }}>
          <AutocompleteInput
            optionText={countryRenderer}
            optionValue="code"
            source="country"
            choices={countries}
          />
          <TextField source="country" />
          {/*{JSON.stringify(countries)}*/}
        </Box>
        {competition &&
        competition.club_field &&
        competition.club_field === "yes" ? (
          <Box
            flex={1}
            ml={{ xs: 0, sm: "0.5em" }}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <ReferenceInput
              perPage={1000000}
              filter={{
                competition: localStorage.getItem("currentCompetition"),
              }}
              source="team"
              reference="teams"
              allwaysOn
            >
              <SelectInput label="Team/Club" optionText="name" />
            </ReferenceInput>
            ,
          </Box>
        ) : null}
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <ReferenceInput
            source="platform"
            reference="platforms"
            filter={{
              competition: localStorage.getItem("currentCompetition")
                ? localStorage.getItem("currentCompetition")
                : null,
            }}
          >
            <SelectInput
              label="Session"
              create={<CreatePlatform />}
              optionText="name"
            />
          </ReferenceInput>
        </Box>
      </Box>
    </SimpleForm>
  );
};

const CreateTeam = (props) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState(filter || "");

  const handleSubmit = (event) => {
    event.preventDefault();
    create(
      props.dbtable,
      {
        data: {
          name: value,
          competition: localStorage.getItem("currentCompetition") || "",
        },
      },
      {
        onSuccess: (data) => {
          setValue("");
          onCreate(data);
        },
      },
    );
  };

  return (
    <Dialog open onClose={onCancel} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MUITextField
            fullWidth
            label="Club name"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CountryInput = () => {
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useController({ name: "country", defaultValue: "" });
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300 }}
      size="small"
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.code}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <MUITextField
          {...params}
          {...field}
          label="Country"
          error={(isTouched || isSubmitted) && invalid}
          helperText={(isTouched || isSubmitted) && invalid ? error : ""}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

const CreatePlatform = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState(filter || "");

  const handleSubmit = (event) => {
    event.preventDefault();
    create(
      "platforms",
      {
        data: {
          name: value,
          competition: localStorage.getItem("currentCompetition") || "",
        },
      },
      {
        onSuccess: (data) => {
          setValue("");
          onCreate(data);
        },
      },
    );
  };

  return (
    <Dialog open onClose={onCancel} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MUITextField
            fullWidth
            label=""
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
