import * as React from "react";
import { useCreate, useCreateSuggestionContext, TextInput, PasswordInput, Button, Form } from 'react-admin';
import {
    // Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box
} from '@mui/material';

const CreateUser = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [create] = useCreate();
    const [value, setValue] = React.useState(filter || '');

    const handleSubmit = event => {
        event.preventDefault();
        create(
          'categories',
          { data: { name: value } },
          {
              onSuccess: (data) => {
                  setValue('');
                  onCreate(data);
              },
          }
        );
    };

    const handleSaveClick = async () => {
        const oFormObject = document.getElementById("QTituloQuickCreateForm");
        console.log(oFormObject)
        const data = new FormData(oFormObject)
        const form = Array.from(data.entries())
        console.log(form)
        const values = {}
        for (const [name, value] of form) {
            console.log({ name, value })
            /*
            {name: "title", value: "a"}
            {name: "text", value: "b"}
            {name: "email", value: "c"}
            */
            values[name]= value
        }
        
        console.log(values)
        create(
          'User',
          { data: values },
          {
              onSuccess: (data) => {
                  setValue('');
                  onCreate(data);
              },
          }
        );
        
        // return;

        // dataProvider.create('User', { data: values })
        //     .then(({ data }) => {
        //         // setShowDialog(false)
        //         // window.location.reload();
        //     })
        //     .catch(error => {
        //         // showNotification(error.message, 'error');
        //         // console.log(error)
        //     })
    };

    return (
        <Dialog open onClose={onCancel} maxWidth="xs" fullWidth>
            {/*<form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Nombre de la nueva Categoría"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Guardar</Button>
                    <Button onClick={onCancel}>Cancelar</Button>
                </DialogActions>
            </form>*/}
            <DialogTitle>This user will have admin permissions on this competition</DialogTitle>
            <DialogContent>
                <Form name="QTituloQuickCreateForm" id="QTituloQuickCreateForm" onSubmit={handleSubmit}>
                    <Box style={{width: "100%"}} mt={{ xs: '0.5em', sm: '0.5em' }} fullWidth={true} display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2}  mr={{ xs: 0, sm: '0.5em' }} >
                            <TextInput fullWidth source="fullname"  />
                        </Box>
                        
                    </Box>
                    <Box style={{width: "100%"}} mt={{ xs: '0.5em', sm: '0.5em' }} fullWidth={true} display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1}  mr={{ xs: 0, sm: '0.5em' }} >
                            <TextInput fullWidth source="username" label="email" />
                        </Box>
                        <Box flex={1}  ml={{ xs: 0, sm: '0.5em' }} >
                            <PasswordInput fullWidth source="password" />
                        </Box>
                    </Box>
                    {/*<SubmitButton>Guardar</SubmitButton>*/}
                </Form>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleSaveClick}
                    label="Guardar"
                />
                <Button label="ra.action.cancel" onClick={onCancel}/>
            </DialogActions>
        </Dialog>
    );
};

export default CreateUser;