import * as React from "react";
import { useState, useEffect, useRef } from "react";

export const PomView = ({
    onStart,
    onResset,
    startAction,
    ressetAction,
    trigger,
}) => {
    const [timer, setTimer] = useState(120); // 25 minutes
    const [start, setStart] = useState(false);
    const firstStart = useRef(true);
    const tick = useRef(); // <-- React ref

    useEffect(() => {
        if (trigger) {
            setStart(false);
            setTimer(120);
        }
    }, [trigger]);

    useEffect(() => {
        if (firstStart.current) {
            firstStart.current = !firstStart.current;
            return;
        }

        if (start) {
            tick.current = setInterval(() => {
                // <-- set tick ref current value
                setTimer((timer) => timer - 1);
            }, 1000);
        } else {
            clearInterval(tick.current); // <-- access tick ref current value
        }

        return () => clearInterval(tick.current); // <-- clear on unmount!
    }, [start]);

    useEffect(() => {
        if (!startAction) {
            onResset(timer);
        } //On pause we save the timer value
        setStart(startAction);
        onStart(startAction);
    }, [startAction]);

    useEffect(() => {
        // if (!start) {
        setStart(false);
        onResset(120);
        setTimer(120);
        // }
    }, [ressetAction]);

    const toggleStart = () => {
        if (start) {
            onResset(timer);
        } //On pause we save the timer value
        setStart(!start);
        onStart(!start);
    };

    const dispSecondsAsMins = (seconds) => {
        // 25:00
        // console.log("seconds " + seconds)
        const mins = Math.floor(seconds / 60);
        const seconds_ = seconds % 60;
        return (
            mins.toString() +
            ":" +
            ("0" + (seconds_ == 0 ? "00" : seconds_.toString())).slice(-2)
        );
    };

    return (
        <div className="pomView">
            <h3 style={{ margin: 0 }}>
                {timer >= 0 ? dispSecondsAsMins(timer) : "00:00"} {`  `}
            </h3>
            <div className="startDiv">
                {/* event handler onClick is function not function call */}
                <button className="startBut" onClick={toggleStart}>
                    {!start ? "START" : "STOP"}
                </button>
                {!start && (
                    <button
                        className="startBut"
                        onClick={() => {
                            setTimer(120);
                            onResset(120);
                        }}
                    >
                        RESET
                    </button>
                )}
                <input
                    onChange={(e) => {
                        if (e && e.target && e.target.value) {
                            setTimer(e.target.value * 60);
                            onResset(e.target.value * 60);
                        }
                    }}
                    style={{ width: "30px", marginLeft: "5px" }}
                    type="number"
                    name="minutes"
                    placeholder="2"
                />
                {/*{start && <AiFillFastForward className="ff" onClick="" />}*/}
            </div>
        </div>
    );
};

export default PomView;
