import * as React from "react";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useGetList,
  Loading,
  useDataProvider,
  TextInput,
  SelectInput,
  TopToolbar,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { MensWc, WomensWc } from "./MensWc";

export const WeightIn = () => {
  return <h1>WeightIn</h1>;
};
