import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  useRecordContext,
  Show,
  SimpleShowLayout,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const RunField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/run/${record.id}`} target="_blank">
      Run
    </a>
  );
};

const BoardField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/board/${record.id}`} target="_blank">
      Open Scoreboard
    </a>
  );
};

const DisplayField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/display/${record.id}`} target="_blank">
      Open attempt board
    </a>
  );
};

const RefereeField = () => {
  const record = useRecordContext();
  return (
    <div>
      <a href={`#/creferee/${record.id}`} target="_blank">
        Chief Referee
      </a>
      <br />
      <a href={`#/lreferee/${record.id}`} target="_blank">
        Left Referee
      </a>
      <br />
      <a href={`#/rreferee/${record.id}`} target="_blank">
        Right Referee
      </a>
    </div>
  );
};

const postFilters = [<TextInput label="Session Name" source="name" />];

export const PostList = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    }
  }, []);
  return (
    <List
      perPage={1000}
      exporter={false}
      filters={postFilters}
      filter={{
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
      }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="expand"
        expand={<PostShow />}
      >
        <TextField source="competition" />
        <TextField source="name" />
        {/*<RunField source="run" label="Run" />
        <BoardField source="board" label="Scoreboard"/>
        <DisplayField source="Attempt Board" />
        <RefereeField source="display" label="Referee Devices"/>*/}
        <EditButton />
      </Datagrid>
    </List>
  );
};

const PostShow = () => {
  const record = useRecordContext();
  const productionUrl = "https://powerlift.worldparapowerlifting.org/";
  return (
    <div>
      <h3>Run the session on another window:</h3>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/run/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Run
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/run/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy the run link
      </Button>
      <br />
      <h3>Scoreboard of this session:</h3>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/board/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Open Scoreboard
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/board/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy the Scoreboard link
      </Button>
      <br />
      <h3>Attempt board of this session:</h3>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/display/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Open attempt board
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/display/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy the attempt board link
      </Button>
      <br />
      <h3>Referees: </h3>
      <Button
        size="small"
        variant="outlined"
        href={`#/creferee/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Chief Referee:{" "}
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/creferee/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy link
      </Button>
      <br />
      <Button
        size="small"
        variant="outlined"
        href={`#/lreferee/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Left Referee:{" "}
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/lreferee/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy link
      </Button>
      <br />
      <Button
        size="small"
        variant="outlined"
        href={`#/rreferee/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Right Referee:{" "}
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/rreferee/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy link
      </Button>
    </div>
  );
};

export const PostEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput
        disabled
        label="Event"
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <TextInput fullWidth source="name" />
    </SimpleForm>
  </Edit>
);

export const PostCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput
        disabled
        label="Event"
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <TextInput fullWidth source="name" />
    </SimpleForm>
  </Create>
);
