import * as React from "react";
import { useState, useEffect, useRef } from "react";

export const PomSubsView = ({ refreshedPlatform }) => {
    const [timer, setTimer] = useState(120); // 25 minutes
    const [start, setStart] = useState(false);
    const firstStart = useRef(true);
    const tick = useRef(); // <-- React ref

    useEffect(() => {
        if (firstStart.current) {
            firstStart.current = !firstStart.current;
            return;
        }

        if (start) {
            tick.current = setInterval(() => {
                // <-- set tick ref current value
                setTimer((timer) => timer - 1);
            }, 1000);
        } else {
            clearInterval(tick.current); // <-- access tick ref current value
        }

        return () => clearInterval(tick.current); // <-- clear on unmount!
    }, [start]);

    const toggleStart = () => {
        setStart(!start);
    };

    useEffect(() => {
        console.log(refreshedPlatform);
        if (refreshedPlatform.timer) {
            setTimer(refreshedPlatform.timer);
        }
        if (refreshedPlatform.start) setStart(true);
        else setStart(false);
    }, [refreshedPlatform]);

    const dispSecondsAsMins = (seconds) => {
        // 25:00
        const mins = Math.floor(seconds / 60);
        const seconds_ = seconds % 60;
        return (
            mins.toString() +
            ":" +
            ("0" + (seconds_ == 0 ? "00" : seconds_.toString())).slice(-2)
        );
    };

    return (
        <div className="pomView">
            <h1 style={{ fontSize: 80, textAlign: "right", margin: 0 }}>
                {timer >= 0 ? dispSecondsAsMins(timer) : "00:00"}
            </h1>
        </div>
    );
};

export default PomSubsView;
