import React from "react";
import { Grid, Box } from "@mui/material";
import Parse from "parse";

const Decision = ({ referee, index }) => (
	<>
		{index !== 5 ? (
			<Box
				backgroundColor={
					referee && referee[index] === "bp"
						? "turquoise"
						: referee && referee[index] === "ds"
							? "blue"
							: referee && referee[index] === "ss"
								? "orange"
								: referee && referee[index] === "ps"
									? "purple"
									: referee && referee[index] === "good"
										? "none"
										: "none"
				}
				component="div"
				sx={{
					borderRadius: 2,
					width: index === 5 ? "93%" : "80%",
					height: 40,
					margin: "auto",
				}}
			/>
		) : (
			<Box
				backgroundColor={
					referee &&
					(referee[0] === "bp" ||
						referee[0] === "ds" ||
						referee[0] === "ss" ||
						referee[0] === "ps")
						? "red"
						: referee && referee[0] === "good"
							? "#ffffff"
							: "none"
				}
				component="div"
				sx={{
					borderRadius: 2,
					width: index === 5 ? "93%" : "80%",
					height: 40,
					margin: "auto",
				}}
			/>
		)}
	</>
);

export const Desitions = (props) => {
	const { platform } = props;
	const [chiefDesicions, setChiefDecisions] = React.useState([]);
	const [leftDesicions, setLeftDecisions] = React.useState([]);
	const [rightDesicions, setRightDecisions] = React.useState([]);

	const livequery = async () => {
		const query = new Parse.Query("chief_referee_decisions");
		query.equalTo("platform", platform);
		const results = await query.find();
		// console.log(results);
		if (results.length > 0) {
			const lastId = results[results.length - 1].id;
			console.log(lastId);
			const obj = results[results.length - 1].toJSON();
			setChiefDecisions(obj.decisions);
			const querySubs = new Parse.Query("chief_referee_decisions");
			querySubs.equalTo("objectId", lastId);
			const subscription1 = await querySubs.subscribe();

			subscription1.on("create", (object) => {
				console.log("object created");
				const obj = object.toJSON();
				setChiefDecisions(obj.decisions);
			});

			subscription1.on("update", async (object) => {
				console.log("Entro a update de la primera subscripción");
				const obj = object.toJSON();
				setChiefDecisions(obj.decisions);
			});
		}
	};

	const livequery2 = async () => {
		const query = new Parse.Query("left_referee_decisions");
		query.equalTo("platform", platform);
		const results = await query.find();
		// console.log(results);
		if (results.length > 0) {
			const lastId = results[results.length - 1].id;
			console.log(lastId);
			const obj = results[results.length - 1].toJSON();
			setLeftDecisions(obj.decisions);
			const querySubs = new Parse.Query("left_referee_decisions");
			querySubs.equalTo("objectId", lastId);
			const subscription1 = await querySubs.subscribe();

			subscription1.on("create", (object) => {
				console.log("object created");
				const obj = object.toJSON();
				setLeftDecisions(obj.decisions);
			});

			subscription1.on("update", async (object) => {
				console.log("Entro a update de la primera subscripción");
				const obj = object.toJSON();
				setLeftDecisions(obj.decisions);
			});
		}
	};

	const livequery3 = async () => {
		const query = new Parse.Query("right_referee_decisions");
		query.equalTo("platform", platform);
		const results = await query.find();
		// console.log(results);
		if (results.length > 0) {
			const lastId = results[results.length - 1].id;
			console.log(lastId);
			const obj = results[results.length - 1].toJSON();
			setRightDecisions(obj.decisions);
			const querySubs = new Parse.Query("right_referee_decisions");
			querySubs.equalTo("objectId", lastId);
			const subscription1 = await querySubs.subscribe();

			subscription1.on("create", (object) => {
				console.log("object created");
				const obj = object.toJSON();
				setRightDecisions(obj.decisions);
			});

			subscription1.on("update", async (object) => {
				console.log("Entro a update de la primera subscripción");
				const obj = object.toJSON();
				setRightDecisions(obj.decisions);
			});
		}
	};

	React.useEffect(() => {
		if (platform) {
			livequery();
			livequery2();
			livequery3();
		}
	}, [platform]);
	return (
		<div
			style={{
				display: "flex",
				border: "1px solid grey",
				padding: 5,
				borderRadius: 8,
				backgroundColor: "black",
			}}
		>
			<Grid container spacing={0}>
				<Grid item xs={12} style={{ marginBottom: "5px" }}>
					<Decision referee={leftDesicions} index={5} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={leftDesicions} index={0} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={leftDesicions} index={1} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={leftDesicions} index={2} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={leftDesicions} index={3} />
				</Grid>
			</Grid>
			<Grid container spacing={0}>
				<Grid item xs={12} style={{ marginBottom: "5px" }}>
					<Decision referee={chiefDesicions} index={5} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={chiefDesicions} index={0} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={chiefDesicions} index={1} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={chiefDesicions} index={2} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={chiefDesicions} index={3} />
				</Grid>
			</Grid>
			<Grid container spacing={0}>
				<Grid item xs={12} style={{ marginBottom: "5px" }}>
					<Decision referee={rightDesicions} index={5} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={rightDesicions} index={0} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={rightDesicions} index={1} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={rightDesicions} index={2} />
				</Grid>
				<Grid item xs={3}>
					<Decision referee={rightDesicions} index={3} />
				</Grid>
			</Grid>
		</div>
	);
};
