import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  NumberInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
  DeleteButton,
  Button,
  AutocompleteInput,
  FormDataConsumer,
  useRecordContext,
  CreateButton,
  useDataProvider,
  useRefresh,
  FunctionField,
  TopToolbar,
  FilterButton,
  useGetList,
  Loading,
  ShowButton,
  SelectArrayInput,
  Show,
  SimpleShowLayout,
  useGetOne,
  WithListContext,
  downloadCSV,
  ExportButton,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import { useController } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import AwardIcon from "@mui/icons-material/EmojiEvents";
import PrintIcon from "@mui/icons-material/Print";
import ListIcon from "@mui/icons-material/List";
import { MensWc, WomensWc } from "./MensWc";
import { Typography, TextField as MUITextField, Stack } from "@mui/material";
// import {Countries as countries} from './Countries'
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Parse from "parse";

// const user = Parse.User.current();
// console.log(user)
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Empty = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  async function crearDivision(cat, i, sex) {
    const { data: competition } = await dataProvider.getOne("competitions", {
      id: localStorage.getItem("currentCompetition"),
    });
    if (competition.divisions.length) {
      for (const age_division of competition.divisions) {
        const { data } = await dataProvider.create("award_divisions", {
          data: {
            name: cat.name,
            categories: [cat.id],
            sex,
            divisions: [age_division],
            competition: localStorage.getItem("currentCompetition"),
            scored_by: "best_lift",
          },
        });
      }
    } else {
      const { data } = await dataProvider.create("award_divisions", {
        data: {
          name: cat.name,
          categories: [cat.id],
          sex,
          divisions: [],
          competition: localStorage.getItem("currentCompetition"),
          scored_by: "best_lift",
        },
      });
    }
    // console.log(data);
    // if(i === results.data.length - 1) {refresh()}
  }

  return (
    <Box sx={{ flex: 1 }} textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No Award Divisions Created
      </Typography>
      <Typography variant="body1">
        Create one or create defaults per category
      </Typography>
      <CreateButton label="Create one" />
      <br />
      <Button
        variant="contained"
        onClick={async () => {
          MensWc.forEach((cat, i) => {
            crearDivision(cat, i, "m");
          });
          WomensWc.forEach((cat, i) => {
            crearDivision(cat, i, "f");
          });
        }}
      >
        <ListIcon /> Create Defaults
      </Button>
    </Box>
  );
};

const EmptyResults = () => {
  return (
    <Box sx={{ flex: 1 }} textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No Award Divisions Created
      </Typography>
      <Typography variant="body1">
        You need to create Award Divisions to organize results
      </Typography>
    </Box>
  );
};

const AwardShow = () => {
  const { id } = useParams();
  const { data: record } = useGetOne("award_divisions", { id });
  // console.log(record)

  const {
    data: teams,
    total5,
    isLoading5,
    error5,
  } = useGetList("teams", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const {
    data: platforms,
    total: total2,
    isLoading: isLoading2,
    error: error2,
  } = useGetList("platforms", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      weight_class: record ? record.categories : null,
      // divisions: record?record.divisions:null
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "fullname", order: "ASC" },
  });
  console.log({
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      weight_class: record ? record.categories : null,
      // divisions: record?record.divisions:null
    },
  });
  console.log(lifters);

  const refreshedLifters =
    lifters && platforms
      ? lifters
          .filter(
            (lifter) =>
              lifter.divisions &&
              lifter.divisions.length &&
              lifter.divisions.some((element) => {
                return (
                  record.divisions &&
                  record.divisions.length &&
                  record.divisions.includes(element)
                );
              }),
          )
          .map((row) => {
            const refreshedPlatform = platforms.find(
              (item) => item.id === row.platform,
            );
            // Debo filtrarlos por division ?
            return {
              ...row,
              best_result:
                refreshedPlatform &&
                refreshedPlatform.third_attempt_results &&
                refreshedPlatform.third_attempt_results[row.id]
                  ? row.third_attempt
                  : refreshedPlatform &&
                      refreshedPlatform.second_attempt_results &&
                      refreshedPlatform.second_attempt_results[row.id]
                    ? row.second_attempt
                    : refreshedPlatform &&
                        refreshedPlatform.first_attempt_results &&
                        refreshedPlatform.first_attempt_results[row.id]
                      ? row.first_attempt
                      : null,
              best_result_attempt:
                refreshedPlatform &&
                refreshedPlatform.third_attempt_results &&
                refreshedPlatform.third_attempt_results[row.id]
                  ? 3
                  : refreshedPlatform &&
                      refreshedPlatform.second_attempt_results &&
                      refreshedPlatform.second_attempt_results[row.id]
                    ? 2
                    : refreshedPlatform &&
                        refreshedPlatform.first_attempt_results &&
                        refreshedPlatform.first_attempt_results[row.id]
                      ? 1
                      : 0,
            };
          })
      : [];

  console.log(refreshedLifters);

  const {
    data: competition,
    total: total3,
    isLoading: isLoading3,
    error: error3,
  } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition")
      ? localStorage.getItem("currentCompetition")
      : null,
  });

  if (
    isLoading ||
    isLoading2 ||
    isLoading3 ||
    !refreshedLifters ||
    !platforms
  ) {
    return <Loading />;
  }

  console.log(error3, error2, error);

  if (error || error2 || error3) {
    return <p>ERROR</p>;
  }

  const imprimir = () => {
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(`<style type="text/css" media="print" />
        body {font-size: 8pt; font-family:'Arial','Times New Roman',Times,serif;}
        table { page-break-inside: avoid;page-break-after:auto }
        tr    { page-break-inside:avoid; page-break-after:auto }
        td    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important; font-family:'Arial','Times New Roman',Times,serif; }
        thead { display:table-header-group }
        tfoot { display:table-footer-group }</style>`);
    pri.document.write(document.getElementById("toprint").innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" />
        <FunctionField
          source="divisions"
          label="Age group"
          render={(record) =>
            `${record.divisions && record.divisions.map((agroup) => agroup.name).join(",")}`
          }
        />
        <FunctionField
          source="categories"
          label="Categories"
          render={(record) =>
            record.sex === "f"
              ? `${record.categories.map((item) => WomensWc.find((wc) => wc.id === item).name).join(", ")}`
              : `${record.categories.map((item) => MensWc.find((wc) => wc.id === item).name).join(", ")}`
          }
        />
        <IconButton
          autoFocus
          color="inherit"
          onClick={() => {
            imprimir();
          }}
        >
          <PrintIcon />
          Print
        </IconButton>
        <iframe
          title="pdf"
          id="ifmcontentstoprint"
          className="pdfIframe"
        ></iframe>
        <Box id="toprint">
          <div
            style={{
              padding: 4,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            colspan={2}
          >
            {competition &&
            competition.pictures &&
            competition.pictures.length &&
            competition.pictures[0].src ? (
              <img src={competition.pictures[0].src} height="40" />
            ) : null}
            <h1>Results</h1>
          </div>
          <TableContainer component={Paper}>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                      width: "15%",
                    }}
                  >
                    Competition
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.meet_name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    City and Country
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.city_town : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition && competition.start_date
                      ? competition.start_date.split("-")[2] +
                        "/" +
                        competition.start_date.split("-")[1] +
                        "/" +
                        competition.start_date.split("-")[0]
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Bodyweight Category
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    <FunctionField
                      source="categories"
                      label="Categories"
                      render={(record) =>
                        record.sex === "f"
                          ? `${record.categories.map((item) => WomensWc.find((wc) => wc.id === item).name).join(", ")}`
                          : `${record.categories.map((item) => MensWc.find((wc) => wc.id === item).name).join(", ")}`
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 2,
              }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    Lot
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    Team
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    DoB
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    BW
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    Rack
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    2
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    3
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    Best
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    Total
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    pDots
                  </TableCell>
                  {/*<TableCell style={{border: 'solid',borderWidth: 1,padding: 4}} align="right">NBR</TableCell>*/}
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    Rank
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refreshedLifters &&
                  refreshedLifters
                    .map((row, index) => {
                      const refreshedPlatform = platforms.find(
                        (item) => item.id === row.platform,
                      );
                      let rank = "-";
                      return {
                        ...row,
                        pdots: pDots(
                          refreshedPlatform &&
                            refreshedPlatform.third_attempt_results &&
                            refreshedPlatform.third_attempt_results[row.id]
                            ? row.third_attempt
                            : refreshedPlatform &&
                                refreshedPlatform.second_attempt_results &&
                                refreshedPlatform.second_attempt_results[row.id]
                              ? row.second_attempt
                              : refreshedPlatform &&
                                  refreshedPlatform.first_attempt_results &&
                                  refreshedPlatform.first_attempt_results[
                                    row.id
                                  ]
                                ? row.first_attempt
                                : null,
                          row.sex,
                          row.bodyweight,
                        ),
                        best:
                          refreshedPlatform &&
                          refreshedPlatform.third_attempt_results &&
                          refreshedPlatform.third_attempt_results[row.id]
                            ? row.third_attempt
                            : refreshedPlatform &&
                                refreshedPlatform.second_attempt_results &&
                                refreshedPlatform.second_attempt_results[row.id]
                              ? row.second_attempt
                              : refreshedPlatform &&
                                  refreshedPlatform.first_attempt_results &&
                                  refreshedPlatform.first_attempt_results[
                                    row.id
                                  ]
                                ? row.first_attempt
                                : null,
                        BWC: [...WomensWc, ...MensWc].find(
                          (item) => item.id === row.weight_class,
                        )
                          ? [...WomensWc, ...MensWc].find(
                              (item) => item.id === row.weight_class,
                            ).name
                          : row.weight_class,
                      };
                    })
                    .sort((a, b) => {
                      if (record.scored_by === "formula")
                        return parseFloat(b.pdots) < parseFloat(a.pdots)
                          ? -1
                          : 1;

                      if (a.best_result === b.best_result) {
                        return parseInt(b.best_result_attempt) <
                          parseInt(a.best_result_attempt)
                          ? 1
                          : -1;
                      } else {
                        return b.best_result < a.best_result ? -1 : 1;
                      }
                      // if(round === "1")
                      //   if (a.first_attempt === b.first_attempt){
                      //     return b.lot_number < a.lot_number ? -1 : 1
                      //   } else {
                      //     return a.first_attempt < b.first_attempt ? -1 : 1
                      //   }

                      // if(round === "2")
                      //   if (a.second_attempt === b.second_attempt){
                      //     return b.lot_number < a.lot_number ? -1 : 1
                      //   } else {
                      //     return a.second_attempt < b.second_attempt ? -1 : 1
                      //   }

                      // if(round === "3")
                      //   if (a.third_attempt === b.third_attempt){
                      //     return b.lot_number < a.lot_number ? -1 : 1
                      //   } else {
                      //     return a.third_attempt < b.third_attempt ? -1 : 1
                      //   }
                    })
                    .map((row, index, mainarr) => {
                      const refreshedPlatform = platforms.find(
                        (item) => item.id === row.platform,
                      );
                      if (
                        refreshedPlatform &&
                        refreshedPlatform.first_attempt_results &&
                        refreshedPlatform.second_attempt_results &&
                        refreshedPlatform.third_attempt_results
                      )
                        return (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {row.lot_number}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {row.fullname}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {/*row.country*/}
                              {teams &&
                              teams.length &&
                              teams.find((item) => item.id === row.team)
                                ? teams.find((item) => item.id === row.team)
                                    .name
                                : null}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {row.date_of_birth}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {row.bodyweight}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {row.rack_height}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {refreshedPlatform &&
                              !refreshedPlatform.first_attempt_results ? (
                                row.first_attempt
                              ) : refreshedPlatform.first_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.first_attempt_results[
                                  row.id
                                ] === true ? (
                                row.first_attempt
                              ) : refreshedPlatform &&
                                refreshedPlatform.first_attempt_results &&
                                refreshedPlatform.first_attempt_results[
                                  row.id
                                ] === false ? (
                                <s>{row.first_attempt}</s>
                              ) : "" + " " + refreshedPlatform &&
                                !refreshedPlatform.first_attempt_records ? (
                                ""
                              ) : refreshedPlatform &&
                                refreshedPlatform.first_attempt_records[
                                  row.id
                                ] ? (
                                ` ${Object.keys(refreshedPlatform.first_attempt_records[row.id])}`
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {refreshedPlatform &&
                              !refreshedPlatform.second_attempt_results ? (
                                row.second_attempt
                              ) : refreshedPlatform.second_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.second_attempt_results[
                                  row.id
                                ] === true ? (
                                row.second_attempt
                              ) : refreshedPlatform &&
                                refreshedPlatform.second_attempt_results &&
                                refreshedPlatform.second_attempt_results[
                                  row.id
                                ] === false ? (
                                <s>{row.second_attempt}</s>
                              ) : (
                                ""
                              )}
                              {refreshedPlatform &&
                              !refreshedPlatform.second_attempt_records
                                ? ""
                                : refreshedPlatform &&
                                    refreshedPlatform.second_attempt_records[
                                      row.id
                                    ]
                                  ? ` ${Object.keys(refreshedPlatform.second_attempt_records[row.id])}`
                                  : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {refreshedPlatform &&
                              !refreshedPlatform.third_attempt_results ? (
                                row.third_attempt
                              ) : refreshedPlatform.third_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.third_attempt_results[
                                  row.id
                                ] === true ? (
                                row.third_attempt
                              ) : refreshedPlatform &&
                                refreshedPlatform.third_attempt_results &&
                                refreshedPlatform.third_attempt_results[
                                  row.id
                                ] === false ? (
                                <s>{row.third_attempt}</s>
                              ) : (
                                "DNF"
                              )}
                              {refreshedPlatform &&
                              !refreshedPlatform.third_attempt_records
                                ? ""
                                : refreshedPlatform &&
                                    refreshedPlatform.third_attempt_records[
                                      row.id
                                    ]
                                  ? ` ${Object.keys(refreshedPlatform.third_attempt_records[row.id])}`
                                  : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {row.best_result ? row.best_result : "-"}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {refreshedPlatform &&
                              refreshedPlatform.first_attempt_results &&
                              refreshedPlatform.second_attempt_results &&
                              refreshedPlatform.third_attempt_results ? (
                                <>
                                  {refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                  refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                    ? row.first_attempt +
                                      row.second_attempt +
                                      row.third_attempt
                                    : null}
                                  {refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                  refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                    ? row.first_attempt + row.second_attempt
                                    : null}
                                  {refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                    ? row.first_attempt + row.third_attempt
                                    : null}
                                  {!refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                  refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                    ? row.second_attempt + row.third_attempt
                                    : null}
                                  {refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                    ? row.first_attempt
                                    : null}
                                  {!refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                  refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                    ? row.second_attempt
                                    : null}
                                  {!refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                    ? row.third_attempt
                                    : null}
                                  {!refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                    ? "-"
                                    : null}
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {row.pdots}
                            </TableCell>
                            {/*<TableCell style={{border: 'solid',borderWidth: 1,padding: 4}} align="right">
                                                      {
                                                        refreshedPlatform && refreshedPlatform.third_attempt_records && refreshedPlatform.third_attempt_records[row.id] ? row.third_attempt :
                                                        refreshedPlatform && refreshedPlatform.second_attempt_records && refreshedPlatform.second_attempt_records[row.id] ? row.second_attempt :
                                                        refreshedPlatform && refreshedPlatform.first_attempt_records && refreshedPlatform.first_attempt_records[row.id] ? row.first_attempt :
                                                        ''
                                                      }
                                                    </TableCell>*/}
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {!refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.third_attempt_results[row.id]
                                ? "-"
                                : index + 1}
                            </TableCell>
                          </TableRow>
                        );
                    })}
              </TableBody>
            </Table>
            {/*<p>Chief Referee: <TextField source="chief_referee" /><br/>
                            Signature:</p>
                            <p>Technical Delegate: <TextField source="tdelegate" /><br/>
                            Signature:</p>*/}
          </TableContainer>
        </Box>
      </SimpleShowLayout>
    </Show>
  );
};

function pDots(best, sex, bw) {
  var inputLabel = "Best Lift",
    inputUnit = "kg",
    outputLabel = "Score",
    outputUnit = "pts",
    steps = 1;
  var a,
    b,
    c,
    d,
    e,
    bwsum,
    result,
    total = 0.0;
  var params = {
    1: {
      A: -7.93954283e-7,
      B: 0.000493457474,
      C: -0.1231642956,
      D: 16.0233664,
      E: 45.59224,
    },
    2: {
      A: -2.55986906e-6,
      B: 0.00116119212,
      C: -0.205352889,
      D: 17.3690866,
      E: 55.4261,
    },
  };

  var gender = sex === "m" || sex === "M" || sex === "male" ? 1 : 2;
  a = params[gender].A;
  b = params[gender].B;
  c = params[gender].C;
  d = params[gender].D;
  e = params[gender].E;

  const value = best;
  bwsum =
    a * Math.pow(bw, 4) +
    b * Math.pow(bw, 3) +
    c * Math.pow(bw, 2) +
    d * bw +
    e;
  var exactResult = (value * 500) / bwsum;
  result = Math.round(exactResult * 1000) / 1000;

  if (!isNaN(result) && result !== Infinity) {
    total += result.toFixed(3);
  }

  var totalResult = Math.round(total * 1000) / 1000;

  if (totalResult) return `${totalResult.toFixed(3)}`;

  return "-";
}

const ListActions = (props) => (
  <TopToolbar>
    {/*<FilterButton/>*/}
    <CreateButton />
    <ExportButton label="Export competition results" maxResults={50000} />
    {/* Add your custom actions */}
    {/*{<Button variant="contained"><FileDownloadIcon /> Export competition results</Button>}*/}
  </TopToolbar>
);

const postFilters = [
  <TextInput source="name" alwaysOn />,
  <SelectInput source="categories" label="Mens WC" choices={MensWc} alwaysOn />,
  <SelectInput
    source="categories"
    label="Womens WC"
    choices={WomensWc}
    alwaysOn
  />,
];

export const AwardList = () => (
  <List
    emptyWhileLoading
    empty={<Empty />}
    filters={postFilters}
    sort={{ field: "name", order: "ASC" }}
    filter={{
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <FunctionField
        source="divisions"
        label="Age Group"
        render={(record) =>
          `${record.divisions && record.divisions.map((agroup) => agroup.name).join(",")}`
        }
      />
      <FunctionField
        source="categories"
        label="BWC"
        render={(record) =>
          record.sex === "f"
            ? `${record.categories.map((item) => WomensWc.find((wc) => wc.id === item).name).join(", ")}`
            : `${record.categories.map((item) => MensWc.find((wc) => wc.id === item).name).join(", ")}`
        }
      />
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const AwardList2 = () => {
  const user = Parse.User.current();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    }
  }, []);

  const { data, isLoading, error } = useGetList("award_divisions", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "published_at", order: "DESC" },
    filter: { competition: localStorage.getItem("currentCompetition") },
  });

  const {
    data: teams,
    total5,
    isLoading5,
    error5,
  } = useGetList("teams", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const {
    data: platforms,
    total: total2,
    isLoading: isLoading2,
    error: error2,
  } = useGetList("platforms", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const {
    data: lifters,
    total,
    isLoading: isLoadingL,
    error: errorL,
  } = useGetList("lifters", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      // weight_class: record ? record.categories : null,
      // divisions: record?record.divisions:null
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "fullname", order: "ASC" },
  });

  const refreshedLifters =
    lifters && platforms
      ? lifters.map((row) => {
          const refreshedPlatform = platforms.find(
            (item) => item.id === row.platform,
          );
          return {
            ...row,
            best_result:
              refreshedPlatform &&
              refreshedPlatform.third_attempt_results &&
              refreshedPlatform.third_attempt_results[row.id]
                ? row.third_attempt
                : refreshedPlatform &&
                    refreshedPlatform.second_attempt_results &&
                    refreshedPlatform.second_attempt_results[row.id]
                  ? row.second_attempt
                  : refreshedPlatform &&
                      refreshedPlatform.first_attempt_results &&
                      refreshedPlatform.first_attempt_results[row.id]
                    ? row.first_attempt
                    : null,
            best_result_attempt:
              refreshedPlatform &&
              refreshedPlatform.third_attempt_results &&
              refreshedPlatform.third_attempt_results[row.id]
                ? 3
                : refreshedPlatform &&
                    refreshedPlatform.second_attempt_results &&
                    refreshedPlatform.second_attempt_results[row.id]
                  ? 2
                  : refreshedPlatform &&
                      refreshedPlatform.first_attempt_results &&
                      refreshedPlatform.first_attempt_results[row.id]
                    ? 1
                    : 0,
          };
        })
      : [];

  const {
    data: competition,
    total: total3,
    isLoading: isLoading3,
    error: error3,
  } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition")
      ? localStorage.getItem("currentCompetition")
      : null,
  });

  const exporter = (award_division) => {
    const rows = [];
    for (var i = 0; i < award_division.length; i++) {
      const record = award_division[i];
      const divisionrows =
        refreshedLifters &&
        refreshedLifters
          .sort((a, b) => {
            return b.best_result < a.best_result ? -1 : 1;
          })
          .filter(
            (lifter) =>
              record.categories.indexOf(lifter.weight_class) >= 0 &&
              lifter.divisions.some(
                (divission) => record.divisions.indexOf(divission) >= 0,
              ),
          );
      // console.log(divisionrows)
      for (var j = 0; j < divisionrows.length; j++) {
        const row = divisionrows[j];
        const refreshedPlatform = platforms.find(
          (item) => item.id === row.platform,
        );
        const record1 =
          refreshedPlatform &&
          refreshedPlatform.first_attempt_records &&
          refreshedPlatform.first_attempt_records[row.id]
            ? " " + Object.keys(refreshedPlatform.first_attempt_records[row.id])
            : "";
        const record2 =
          refreshedPlatform &&
          refreshedPlatform.second_attempt_records &&
          refreshedPlatform.second_attempt_records[row.id]
            ? " " +
              Object.keys(refreshedPlatform.second_attempt_records[row.id])
            : "";
        const record3 =
          refreshedPlatform &&
          refreshedPlatform.third_attempt_records &&
          refreshedPlatform.third_attempt_records[row.id]
            ? " " + Object.keys(refreshedPlatform.third_attempt_records[row.id])
            : "";
        if (refreshedPlatform)
          rows.push({
            award_division: record.name,
            lot_no: record.lot_number,
            name: row.fullname,
            country: row.country,
            team:
              teams &&
              teams.length &&
              teams.find((item) => item.id === row.team)
                ? teams.find((item) => item.id === row.team).name
                : "",
            dob: row.date_of_birth,
            bw: row.bodyweight,
            weight_class: row.weight_class,
            name: row.fullname,
            country: row.country,
            team:
              teams &&
              teams.length &&
              teams.find((item) => item.id === row.team)
                ? teams.find((item) => item.id === row.team).name
                : "",
            dob: row.date_of_birth,
            bw: row.bodyweight,
            weight_class: row.weight_class,
            first_attempt:
              refreshedPlatform.first_attempt_results &&
              refreshedPlatform.first_attempt_results[row.id] &&
              refreshedPlatform.first_attempt_results[row.id] === true
                ? row.first_attempt.toString()
                : refreshedPlatform.first_attempt_results &&
                    refreshedPlatform.first_attempt_results[row.id] &&
                    refreshedPlatform.first_attempt_results[row.id] === false
                  ? "-" + row.first_attempt.toString()
                  : "" + record1,
            second_attempt:
              refreshedPlatform.second_attempt_results &&
              refreshedPlatform.second_attempt_results[row.id] &&
              refreshedPlatform.second_attempt_results[row.id] === true
                ? row.second_attempt.toString()
                : refreshedPlatform.second_attempt_results &&
                    refreshedPlatform.second_attempt_results[row.id] &&
                    refreshedPlatform.second_attempt_results[row.id] === false
                  ? "-" + row.second_attempt.toString()
                  : "" + record2,
            third_attempt:
              refreshedPlatform.third_attempt_results &&
              refreshedPlatform.third_attempt_results[row.id] &&
              refreshedPlatform.third_attempt_results[row.id] === true
                ? row.third_attempt.toString()
                : refreshedPlatform.third_attempt_results &&
                    refreshedPlatform.third_attempt_results[row.id] &&
                    refreshedPlatform.third_attempt_results[row.id] === false
                  ? "-" + row.third_attempt.toString()
                  : "DNF" + record3,
            best: row.best_result ? row.best_result.toString() : "-",

            total:
              refreshedPlatform &&
              refreshedPlatform.first_attempt_results &&
              refreshedPlatform.second_attempt_results &&
              refreshedPlatform.third_attempt_results
                ? refreshedPlatform.first_attempt_results[row.id] &&
                  refreshedPlatform.second_attempt_results[row.id] &&
                  refreshedPlatform.third_attempt_results[row.id]
                  ? row.first_attempt + row.second_attempt + row.third_attempt
                  : null +
                        " " +
                        refreshedPlatform.first_attempt_results[row.id] &&
                      refreshedPlatform.second_attempt_results[row.id] &&
                      !refreshedPlatform.third_attempt_results[row.id]
                    ? row.first_attempt + row.second_attempt
                    : null +
                          " " +
                          refreshedPlatform.first_attempt_results[row.id] &&
                        !refreshedPlatform.second_attempt_results[row.id] &&
                        refreshedPlatform.third_attempt_results[row.id]
                      ? row.first_attempt + row.third_attempt
                      : null +
                            " " +
                            !refreshedPlatform.first_attempt_results[row.id] &&
                          refreshedPlatform.second_attempt_results[row.id] &&
                          refreshedPlatform.third_attempt_results[row.id]
                        ? row.second_attempt + row.third_attempt
                        : null +
                              " " +
                              refreshedPlatform.first_attempt_results[row.id] &&
                            !refreshedPlatform.second_attempt_results[row.id] &&
                            !refreshedPlatform.third_attempt_results[row.id]
                          ? row.first_attempt
                          : null +
                                " " +
                                !refreshedPlatform.first_attempt_results[
                                  row.id
                                ] &&
                              refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.third_attempt_results[row.id]
                            ? row.second_attempt
                            : null +
                                  " " +
                                  !refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] &&
                                !refreshedPlatform.second_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.third_attempt_results[row.id]
                              ? row.third_attempt
                              : null +
                                    " " +
                                    !refreshedPlatform.first_attempt_results[
                                      row.id
                                    ] &&
                                  !refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] &&
                                  !refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]
                                ? "-"
                                : null
                : "-",
            nbr:
              refreshedPlatform &&
              refreshedPlatform.third_attempt_records &&
              refreshedPlatform.third_attempt_records[row.id]
                ? row.third_attempt
                : refreshedPlatform &&
                    refreshedPlatform.second_attempt_records &&
                    refreshedPlatform.second_attempt_records[row.id]
                  ? row.second_attempt
                  : refreshedPlatform &&
                      refreshedPlatform.first_attempt_records &&
                      refreshedPlatform.first_attempt_records[row.id]
                    ? row.first_attempt
                    : "",
            place:
              !refreshedPlatform.first_attempt_results[row.id] &&
              !refreshedPlatform.second_attempt_results[row.id] &&
              !refreshedPlatform.third_attempt_results[row.id]
                ? "-"
                : j + 1,
          });
      }
    }
    console.log(rows);
    // award_division.forEach(record => {
    //     // const { backlinks, author, ...postForExport } = post; // omit backlinks and author
    //     // postForExport.author_name = post.author.name; // add a field

    //     row.forEach((row, index) => {
    //       const refreshedPlatform = platforms.find(item => item.id === row.platform);
    //       results.push({
    //         award_division: record.name,
    //         lot_no: record.lot_number,
    //         name: row.fullname,
    //         country: row.country,
    //         team: teams && teams.length && teams.find(item => item.id === row.team) ? teams.find(item => item.id === row.team).name : "",
    //         dob: row.date_of_birth,
    //         bw: row.bodyweight,
    //         weight_class: row.weight_class,
    //     });
    //   })
    // results.push(row)
    // console.log(row);
    // return row;
    // });

    jsonExport(
      rows,
      {
        rows: [
          "award_division",
          "lot_no",
          "name",
          "country",
          "team",
          "dob",
          "bw",
          "weight_class",
          "first_attempt",
          "second_attempt",
          "third_attempt",
          "best",
          "total",
          "nbr",
          "place",
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, competition.name + "-Results"); // download as 'results.csv` file
      },
    );
  };

  if (
    isLoading ||
    isLoading2 ||
    isLoading3 ||
    !refreshedLifters ||
    isLoadingL
  ) {
    return <Loading />;
  }
  if (error || error2 || error3 || errorL) {
    return <p>ERROR</p>;
  }

  // console.log(data)
  if (!data || !data.length) return <Empty />;

  const imprimir = () => {
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(`<style type="text/css" media="print" />
        body {font-size: 8pt; font-family:'Arial','Times New Roman',Times,serif;}
        table { page-break-inside: avoid;page-break-after:auto }
        tr    { page-break-inside:avoid; page-break-after:auto }
        td    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important; font-family:'Arial','Times New Roman',Times,serif; }
        thead { display:table-header-group; font-size: 10pt; }
        tfoot { display:table-footer-group }</style>`);
    pri.document.write(document.getElementById("toprint").innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <List
      perPage={10000}
      pagination={null}
      exporter={exporter}
      emptyWhileLoading
      actions={<ListActions />}
      empty={<EmptyResults />}
      filters={postFilters}
      sort={{ field: "name", order: "ASC" }}
      resource="award_divisions"
      filter={{
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
      }}
    >
      {/*<Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <FunctionField source="divisions" label="Age divisions" render={record => `${record.divisions.join(", ")}`} />
       <FunctionField source="categories" label="Age divisions" render={record => record.sex === 'f' ?
        `${record.categories.map(item => WomensWc.find(wc => wc.id === item).name).join(", ")}`
        :  `${record.categories.map(item => MensWc.find(wc => wc.id === item).name).join(", ")}`
        } />
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>*/}
      <IconButton
        autoFocus
        color="inherit"
        onClick={() => {
          imprimir();
        }}
      >
        <PrintIcon />
        Print
      </IconButton>
      <iframe
        title="pdf"
        id="ifmcontentstoprint"
        className="pdfIframe"
      ></iframe>
      <WithListContext
        render={({ data }) => (
          <Stack id="toprint" spacing={2} sx={{ padding: 2 }}>
            {[
              ...data
                .filter((record) =>
                  WomensWc.some((WomensCat) =>
                    record.categories.includes(WomensCat.id),
                  ),
                )
                .sort((a, b) => {
                  if (a.name > b.name) return 1;
                  if (a.name < b.name && !a.name.toLowerCase().includes("over"))
                    return -1;
                  return 0;
                }),
              ...data
                .filter((record) =>
                  MensWc.some((MensCat) =>
                    record.categories.includes(MensCat.id),
                  ),
                )
                .sort((a, b) => {
                  if (a.name > b.name) return 1;
                  if (a.categories.includes("107")) return 1;
                  if (a.name < b.name && !a.name.toLowerCase().includes("over"))
                    return -1;
                  return 0;
                }),
            ].map((record) => (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <FunctionField
                    record={record}
                    source="categories"
                    label="Categories"
                    render={(record) => (
                      <h3
                        style={{ marginTop: 25, marginBottom: 15 }}
                      >{`${record.categories.map((item) => [...WomensWc, ...MensWc].find((wc) => wc.id === item).name).join(", ")} ${record.divisions && record.divisions.map((agroup) => agroup.name).join(",")}`}</h3>
                    )}
                  />
                  {/*<IconButton autoFocus color="inherit" onClick={()=>{
                                              imprimir()
                                            }}>
                                              <PrintIcon />
                                              Print
                                            </IconButton>
                                            <Box display="none">
                    <EditButton record={record} />
                    <ShowButton record={record} />
                    <DeleteButton record={record} />
                  </Box>*/}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TableContainer component={Paper}>
                    <Table
                      className="myFormat"
                      size="small"
                      style={{
                        width: "100%",
                        border: "solid",
                        borderWidth: 1,
                        borderCollapse: "collapse",
                        marginBottom: 22,
                        marginTop: 0,
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                              fontWeight: "bold",
                              width: "15%",
                            }}
                          >
                            Competition
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            colspan={2}
                          >
                            {competition ? competition.meet_name : null}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                              fontWeight: "bold",
                            }}
                          >
                            City and Country
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            colspan={2}
                          >
                            {competition ? competition.city_town : null}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                              fontWeight: "bold",
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            colspan={2}
                          >
                            {competition && competition.start_date
                              ? competition.start_date.split("-")[2] +
                                "/" +
                                competition.start_date.split("-")[1] +
                                "/" +
                                competition.start_date.split("-")[0]
                              : null}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                              fontWeight: "bold",
                            }}
                          >
                            Bodyweight Category
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            colspan={2}
                          >
                            <FunctionField
                              source="categories"
                              label="Categories"
                              render={(record) =>
                                record.sex === "f"
                                  ? `${record.categories.map((item) => WomensWc.find((wc) => wc.id === item).name).join(", ")}`
                                  : `${record.categories.map((item) => MensWc.find((wc) => wc.id === item).name).join(", ")}`
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table
                      style={{
                        width: "100%",
                        border: "solid",
                        borderWidth: 1,
                        borderCollapse: "collapse",
                        marginBottom: 2,
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            Lot
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            Team
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            DoB
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            BW
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            Rack
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            1
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            2
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            3
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            Best
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            Total
                          </TableCell>
                          {/*<TableCell style={{border: 'solid',borderWidth: 1,padding: 4}} align="right">NBR</TableCell>*/}
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            Rank
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {refreshedLifters &&
                          refreshedLifters
                            .sort((a, b) => {
                              if (a.best_result === b.best_result) {
                                return parseInt(b.best_result_attempt) <
                                  parseInt(a.best_result_attempt)
                                  ? 1
                                  : -1;
                              } else {
                                return b.best_result < a.best_result ? -1 : 1;
                              }
                            })
                            .filter(
                              (lifter) =>
                                record.categories.indexOf(
                                  lifter.weight_class,
                                ) >= 0 &&
                                lifter.divisions.some(
                                  (divission) =>
                                    record.divisions.indexOf(divission) >= 0,
                                ),
                            )
                            .map((row, index) => {
                              const refreshedPlatform = platforms.find(
                                (item) => item.id === row.platform,
                              );
                              if (
                                refreshedPlatform &&
                                refreshedPlatform.first_attempt_results &&
                                refreshedPlatform.second_attempt_results &&
                                refreshedPlatform.third_attempt_results
                              )
                                return (
                                  <TableRow
                                    key={row.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {row.lot_number}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {row.fullname}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {/*row.country*/}
                                      {teams &&
                                      teams.length &&
                                      teams.find((item) => item.id === row.team)
                                        ? teams.find(
                                            (item) => item.id === row.team,
                                          ).name
                                        : null}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {row.date_of_birth}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {row.bodyweight}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {row.rack_height}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {refreshedPlatform &&
                                      !refreshedPlatform.first_attempt_results ? (
                                        row.first_attempt
                                      ) : refreshedPlatform
                                          .first_attempt_results[row.id] &&
                                        refreshedPlatform.first_attempt_results[
                                          row.id
                                        ] === true ? (
                                        row.first_attempt
                                      ) : refreshedPlatform.first_attempt_results &&
                                        refreshedPlatform.first_attempt_results[
                                          row.id
                                        ] === false ? (
                                        <s>{row.first_attempt}</s>
                                      ) : "" + " " + refreshedPlatform &&
                                        !refreshedPlatform.first_attempt_records ? (
                                        ""
                                      ) : refreshedPlatform
                                          .first_attempt_records[row.id] ? (
                                        ` ${Object.keys(refreshedPlatform.first_attempt_records[row.id])}`
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {refreshedPlatform &&
                                      !refreshedPlatform.second_attempt_results ? (
                                        row.second_attempt
                                      ) : refreshedPlatform
                                          .second_attempt_results[row.id] &&
                                        refreshedPlatform
                                          .second_attempt_results[row.id] ===
                                          true ? (
                                        row.second_attempt
                                      ) : refreshedPlatform.second_attempt_results &&
                                        refreshedPlatform
                                          .second_attempt_results[row.id] ===
                                          false ? (
                                        <s>{row.second_attempt}</s>
                                      ) : (
                                        ""
                                      )}
                                      {refreshedPlatform &&
                                      !refreshedPlatform.second_attempt_records
                                        ? ""
                                        : refreshedPlatform
                                              .second_attempt_records[row.id]
                                          ? ` ${Object.keys(refreshedPlatform.second_attempt_records[row.id])}`
                                          : ""}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {refreshedPlatform &&
                                      !refreshedPlatform.third_attempt_results ? (
                                        row.third_attempt
                                      ) : refreshedPlatform
                                          .third_attempt_results[row.id] &&
                                        refreshedPlatform.third_attempt_results[
                                          row.id
                                        ] === true ? (
                                        row.third_attempt
                                      ) : refreshedPlatform.third_attempt_results &&
                                        refreshedPlatform.third_attempt_results[
                                          row.id
                                        ] === false ? (
                                        <s>{row.third_attempt}</s>
                                      ) : (
                                        "DNF"
                                      )}
                                      {refreshedPlatform &&
                                      !refreshedPlatform.third_attempt_records
                                        ? ""
                                        : refreshedPlatform
                                              .third_attempt_records[row.id]
                                          ? ` ${Object.keys(refreshedPlatform.third_attempt_records[row.id])}`
                                          : ""}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {row.best_result ? row.best_result : "-"}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {refreshedPlatform &&
                                      refreshedPlatform.first_attempt_results &&
                                      refreshedPlatform.second_attempt_results &&
                                      refreshedPlatform.third_attempt_results ? (
                                        <>
                                          {refreshedPlatform
                                            .first_attempt_results[row.id] &&
                                          refreshedPlatform
                                            .second_attempt_results[row.id] &&
                                          refreshedPlatform
                                            .third_attempt_results[row.id]
                                            ? row.first_attempt +
                                              row.second_attempt +
                                              row.third_attempt
                                            : null}
                                          {refreshedPlatform
                                            .first_attempt_results[row.id] &&
                                          refreshedPlatform
                                            .second_attempt_results[row.id] &&
                                          !refreshedPlatform
                                            .third_attempt_results[row.id]
                                            ? row.first_attempt +
                                              row.second_attempt
                                            : null}
                                          {refreshedPlatform
                                            .first_attempt_results[row.id] &&
                                          !refreshedPlatform
                                            .second_attempt_results[row.id] &&
                                          refreshedPlatform
                                            .third_attempt_results[row.id]
                                            ? row.first_attempt +
                                              row.third_attempt
                                            : null}
                                          {!refreshedPlatform
                                            .first_attempt_results[row.id] &&
                                          refreshedPlatform
                                            .second_attempt_results[row.id] &&
                                          refreshedPlatform
                                            .third_attempt_results[row.id]
                                            ? row.second_attempt +
                                              row.third_attempt
                                            : null}
                                          {refreshedPlatform
                                            .first_attempt_results[row.id] &&
                                          !refreshedPlatform
                                            .second_attempt_results[row.id] &&
                                          !refreshedPlatform
                                            .third_attempt_results[row.id]
                                            ? row.first_attempt
                                            : null}
                                          {!refreshedPlatform
                                            .first_attempt_results[row.id] &&
                                          refreshedPlatform
                                            .second_attempt_results[row.id] &&
                                          !refreshedPlatform
                                            .third_attempt_results[row.id]
                                            ? row.second_attempt
                                            : null}
                                          {!refreshedPlatform
                                            .first_attempt_results[row.id] &&
                                          !refreshedPlatform
                                            .second_attempt_results[row.id] &&
                                          refreshedPlatform
                                            .third_attempt_results[row.id]
                                            ? row.third_attempt
                                            : null}
                                          {!refreshedPlatform
                                            .first_attempt_results[row.id] &&
                                          !refreshedPlatform
                                            .second_attempt_results[row.id] &&
                                          !refreshedPlatform
                                            .third_attempt_results[row.id]
                                            ? "-"
                                            : null}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </TableCell>
                                    {/*<TableCell style={{border: 'solid',borderWidth: 1,padding: 4}} align="right">
                                                                              {
                                                                                refreshedPlatform && refreshedPlatform.third_attempt_records && refreshedPlatform.third_attempt_records[row.id] ? row.third_attempt :
                                                                                refreshedPlatform && refreshedPlatform.second_attempt_records && refreshedPlatform.second_attempt_records[row.id] ? row.second_attempt :
                                                                                refreshedPlatform && refreshedPlatform.first_attempt_records && refreshedPlatform.first_attempt_records[row.id] ? row.first_attempt :
                                                                                ''
                                                                              }
                                                                            </TableCell>*/}
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {!refreshedPlatform.first_attempt_results[
                                        row.id
                                      ] &&
                                      !refreshedPlatform.second_attempt_results[
                                        row.id
                                      ] &&
                                      !refreshedPlatform.third_attempt_results[
                                        row.id
                                      ]
                                        ? "-"
                                        : index + 1}
                                    </TableCell>
                                  </TableRow>
                                );
                            })}
                      </TableBody>
                    </Table>
                    {/*<p>Chief Referee: <TextField record={record} source="chief_referee" /><br/>
                                                    Signature:</p>
                                                    <p>Technical Delegate: <TextField  record={record} source="tdelegate" /><br/>
                                                    Signature:</p>*/}
                  </TableContainer>
                </Box>
              </>
            ))}
          </Stack>
        )}
      />
    </List>
  );
};

export const AwardEdit = () => {
  return (
    <Edit>
      <DetailsForm />
    </Edit>
  );
};

export const AwardCreate = () => {
  const user = Parse.User.current();
  return (
    <Create>
      <DetailsForm />
    </Create>
  );
};

const DetailsForm = () => {
  const user = Parse.User.current();
  const { id } = useParams();

  return (
    <SimpleForm>
      <TextInput
        label="Competition"
        disabled
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <TextInput fullWidth source="name" />
      {/*<TextInput fullWidth source="chief_referee" />
      <TextInput fullWidth source="tdelegate" />*/}
      <AutocompleteArrayInput
        source="divisions"
        label="Age Group"
        choices={[
          { id: "Elite", name: "Elite" },
          { id: "Rookie", name: "Rookie" },
          { id: "Next Gen", name: "Next Gen" },
          { id: "Legend", name: "Legend" },
        ]}
      />
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box
          flex={0.5}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <SelectInput
                source="sex"
                choices={[
                  { id: "f", name: "F" },
                  { id: "m", name: "M" },
                ]}
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData.sex === "m" ? (
                <>
                  <SelectArrayInput
                    fullWidth
                    label="Men's Weight Classes (kg)"
                    source="categories"
                    choices={MensWc}
                  />
                </>
              ) : formData.sex === "f" ? (
                <>
                  <SelectArrayInput
                    fullWidth
                    label="Women's Weight Classes (kg)"
                    source="categories"
                    choices={WomensWc}
                  />
                </>
              ) : null;
            }}
          </FormDataConsumer>
        </Box>
      </Box>
      <SelectInput
        source="scored_by"
        choices={[
          { id: "best_lift", name: "Best Lift" },
          { id: "formula", name: "PDots" },
        ]}
      />
    </SimpleForm>
  );
};

// const countryRenderer = option => `🇦🇩 ${option.label} (${option.code})`;
const countryRenderer = (option) => ` ${option.label} (${option.code})`;

const ColorInput = (props) => {
  const input1 = useController({
    name: props.source,
    defaultValue: props.defaultValue,
  });

  return <input {...input1.field} type="color" />;
};

export default {
  list: AwardList,
  edit: AwardEdit,
  create: AwardCreate,
  show: AwardShow,
  icon: AwardIcon,
};
