/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
  import Parse from 'parse';
  const parseConfig = {
    URL: 'https://wppo.freiesland.com/parapower',
    JAVASCRIPT_KEY: null,
    APP_ID: 'ParaPowerCr0b51',
    MASTER_KEY: 'ParaPowerm4st3rk3y',
  }

 Parse.initialize(parseConfig.APP_ID, parseConfig.JAVASCRIPT_KEY);
//  Parse.masterKey = 'b0t3c0m4st3rk3y';
 Parse.serverURL = parseConfig.URL;
 
 // let query = new Parse.Query('Servicios');
 // let subscription = query.subscribe();
 // subscription.on('open', () => {
 //  console.log('subscription opened');
 // });
 
 const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const originalFile = file.rawFile;
    const fileT = new File([originalFile], file.title, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    })
    // var parseFile = new Parse.File(params.data.pictures.rawFile.name, { base64: base64 });
    const filename = originalFile.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').substring(0, 28).trim();
    const parseFile = new Parse.File(filename, fileT);
   return parseFile.save().then(
     function(){
        console.log(parseFile.url());
       return resolve(parseFile.url().replace("http://localhost:1337", "https://wppo.freiesland.com").replace("https://interactiva.portuguesplus.co", "https://wppo.freiesland.com"));
     }, function(error) {
       return resolve(error);
     }
   );
 });
 
 /**
  * For posts update only, convert uploaded image in base 64 and attach it to
  * the `picture` sent property, with `src` and `title` attributes.
  */
 const addUploadFeature = requestHandler => (type, resource, params) => {
   if (type === 'UPDATE' || type === 'CREATE' && resource === 'competitions') {
     // notice that following condition can be true only when `<ImageInput source="pictures" />`
     //component has parameter `multiple={true}`
     // if parameter `multiple` is false, then data.pictures is not an array,
     // but single object
 
         if (params.data.pictures && params.data.pictures.length) {
             // only freshly dropped pictures are instance of File
             const formerPictures = params.data.pictures.filter(p => !(p.rawFile instanceof File));
             const newPictures = params.data.pictures.filter(p => p.rawFile instanceof File);
 
             return Promise.all(newPictures.map(convertFileToBase64))
                 .then(base64Pictures => base64Pictures.map((picture64, index) => ({
                     src: picture64.replace("http://localhost:1337", "https://wppo.freiesland.com").replace("https://interactiva.portuguesplus.co", "https://wppo.freiesland.com"),
                     title: `${newPictures[index].title}`,
                 })))
                 .then(transformedNewPictures => requestHandler(type, resource, {
                     ...params,
                     data: {
                         ...params.data,
                         pictures: [...transformedNewPictures, ...formerPictures],
                     },
                 }));
         }
     }
     // for other request types and resources, fall back to the default request handler
     return requestHandler(type, resource, params);
 };
 
 export default addUploadFeature;
 