import {
  Card,
  CardContent,
  CardHeader,
  Button as MUIButton,
} from "@mui/material";
import { Button, useGetList, Loading, useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";
import Parse from "parse";
export const Dashboard = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const user = Parse.User.current();

  // if (!user) {
  //   navigate("/login");
  // }

  const {
    data: data1,
    total,
    isLoading,
    error,
  } = useGetList("competitions", {
    filter: { userId: user ? user.id : null },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "start_date", order: "DESC" },
  });

  const {
    data: data2,
    total2,
    isLoading2,
    error2,
  } = useGetList("competitions", {
    filter: { users_ids: user ? user.id : null },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "start_date", order: "DESC" },
  });

  // if (isLoading) { return <Loading />; }
  if (error || !user) {
    return <p>ERROR</p>;
  }

  const data = data1 && data2 ? data1.concat(data2) : data2 ? data2 : data1;

  return (
    <Card>
      <CardHeader title={translate("pos.dashboard.welcome.title")} />
      <CardContent>
        <Button
          onClick={() => navigate("/competitions/create")}
          variant="contained"
          color="secondary"
          label="Create competition"
        />
        <h3>My competitions</h3>
        <p>Click on the competition you want to get in</p>
        <ul>
          {data &&
            data.map((record) => (
              <li key={record.id}>
                <Button
                  onClick={() => {
                    alert(record.meet_name + " selected");
                    localStorage.setItem("currentCompetition", record.id);
                    localStorage.setItem(
                      "currentCompetitionName",
                      record.meet_name,
                    );
                    navigate("/competitions/" + record.id);
                    // competitions/JFOuhEmz5w
                  }}
                >
                  {record.meet_name}
                </Button>
              </li>
            ))}
        </ul>
      </CardContent>
    </Card>
  );
};
