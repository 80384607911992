import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from "react-admin";
import Parse from "parse";

const toBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);
        reader.onload = (event) => {
            var base64 = event.target.result;
            resolve(base64);
        };
    });
};

export default ({ URL, APP_ID, JAVASCRIPT_KEY, MASTER_KEY }) => {
    if (Parse.applicationId == null || Parse.javaScriptKey == null) {
        Parse.initialize(APP_ID, JAVASCRIPT_KEY, MASTER_KEY);
        Parse.serverURL = URL;
    }
    return async (type, resource, params) => {
        const resourceObj = Parse.Object.extend(resource);
        const query = new Parse.Query(resourceObj);

        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const { filter } = params;
                const count = await query.count();
                query.limit(perPage);
                query.skip((page - 1) * perPage);

                if (order === "DESC") query.descending(field);
                else if (order === "ASEC") query.ascending(field);
                Object.keys(filter).map((f) => {
                    if (filter[f] === "true" || filter[f] === "false") {
                        if (filter[f] === "true") query.exists("f");
                        else query.doesNotExist("f");
                    } else {
                        if (f === "weight_class") {
                            if (filter[f] && typeof filter[f] === "object")
                                query.containedIn(f, filter[f]); // contains
                            else query.equalTo("weight_class", filter[f]);
                        } else if (f === "q") {
                            query.matches("nombre", filter[f], "i");
                        } else {
                            // console.log(typeof(filter[f]))
                            if (filter[f] && typeof filter[f] === "object")
                                query.containedIn(f, filter[f]); // contains
                            else query.matches(f, filter[f], "i"); // contains
                        }
                    }
                });
                const results = await query.find();
                return {
                    total: count,
                    data: results.map((o) => ({ id: o.id, ...o.attributes })),
                };
            }
            case GET_ONE: {
                const result = await query.get(params.id);
                return {
                    data: { id: result.id, ...result.attributes },
                };
            }
            case GET_MANY: {
                const results = params.ids.map((id) =>
                    new Parse.Query(resourceObj).get(id),
                );
                const data = await Promise.all(results);
                return {
                    total: data.length,
                    data: data.map((o) => ({ id: o.id, ...o.attributes })),
                };
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                query.equalTo(params.target, params.id);
                const count = await query.count();
                query.limit(perPage);
                if (!perPage) query.limit(1000);
                query.skip((page - 1) * perPage);
                if (order === "DESC") query.descending(field);
                else if (order === "ASEC") query.ascending(field);

                const results = await query.find();
                return {
                    total: count,
                    data: results.map((o) => ({ id: o.id, ...o.attributes })),
                };
            }
            case CREATE: {
                const resObj = new resourceObj();
                Object.keys(params.data).map((key) =>
                    resObj.set(key, params.data[key]),
                );
                try {
                    const r = await resObj.save(params.data);
                    // console.log(r);
                    // console.log(resObj)
                    // .then((gameScore) => {
                    //     // The object was saved successfully.
                    //   }, (error) => {
                    //     console.log(error)
                    //     // The save failed.
                    //     // error is a Parse.Error with an error code and message.
                    //   });
                    return { data: { id: resObj.id, ...resObj.attributes } };
                } catch (error) {
                    return {
                        data: { id: resObj.id, ...resObj.attributes },
                        error: error,
                    };
                }
            }
            case UPDATE: {
                var file = null;
                // console.log(params);

                try {
                    const obj = await query.get(params.id);
                    const keys = Object.keys(params.data).filter((o) =>
                        o == "id" || o == "createdAt" || o == "updatedAt"
                            ? false
                            : true,
                    );
                    const data = keys.reduce((r, f, i) => {
                        r[f] = params.data[f];
                        return r;
                    }, {});
                    const r = await obj.save(data);
                    return { data: { id: r.id, ...r.attributes } };
                } catch (error) {
                    throw Error(error.toString());
                }
            }
            case UPDATE_MANY: {
                try {
                    const qs = await Promise.all(
                        params.ids.map((id) =>
                            new Parse.Query(resourceObj).get(id),
                        ),
                    );
                    qs.map((q) => q.save(params.data));
                    return { data: params.ids };
                } catch {
                    throw Error("Failed to update all");
                }
            }
            case DELETE: {
                try {
                    const obj = await query.get(params.id);
                    const data = { data: { id: obj.id, ...obj.attributes } };
                    await obj.destroy();
                    return data;
                } catch (error) {
                    throw Error("Unable to delete");
                }
            }
            case DELETE_MANY: {
                try {
                    const qs = await Promise.all(
                        params.ids.map((id) =>
                            new Parse.Query(resourceObj).get(id),
                        ),
                    );
                    await Promise.all(qs.map((obj) => obj.destroy()));
                    return { data: params.ids };
                } catch (error) {
                    throw Error("Unable to delete all");
                }
            }
        }
    };
};
