export const Countries = [
    {
        code: "AFG",
        label: "Afghanistan",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "AFG.jpg"
    },
    {
        code: "ALB",
        label: "Albania",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "ALB.jpg"
    },
    {
        code: "ALG",
        label: "Algeria",
        Region: "Africa",
        SubRegion: "North",
        MemberType: "country",
        flag: "ALG.jpg"
    },
    {
        code: "AND",
        label: "Andorra",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "AND.jpg"
    },
    {
        code: "ANG",
        label: "Angola",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "ANG.jpg"
    },
    {
        code: "ANT",
        label: "Antigua and Barbuda",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "ANT.jpg"
    },
    {
        code: "ARG",
        label: "Argentina",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "ARG.jpg"
    },
    {
        code: "ARM",
        label: "Armenia",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "ARM.jpg"
    },
    {
        code: "ARU",
        label: "Aruba",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "ARU.jpg"
    },
    {
        code: "ASA",
        label: "American Samoa",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "ASA.jpg"
    },
    {
        code: "AUS",
        label: "Australia",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "AUS.jpg"
    },
    {
        code: "AUT",
        label: "Austria",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "AUT.jpg"
    },
    {
        code: "AZE",
        label: "Azerbaijan",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "AZE.jpg"
    },
    {
        code: "BAH",
        label: "Bahamas",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "BAH.jpg"
    },
    {
        code: "BAN",
        label: "Bangladesh",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "BAN.jpg"
    },
    {
        code: "BAR",
        label: "Barbados",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "BAR.jpg"
    },
    {
        code: "BDI",
        label: "Burundi",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "BDI.jpg"
    },
    {
        code: "BEL",
        label: "Belgium",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "BEL.jpg"
    },
    {
        code: "BEN",
        label: "Benin",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "BEN.jpg"
    },
    {
        code: "BER",
        label: "Bermuda",
        Region: "America",
        SubRegion: "North",
        MemberType: "country",
        flag: "BER.jpg"
    },
    {
        code: "BHU",
        label: "Bhutan",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "BHU.jpg"
    },
    {
        code: "BIH",
        label: "Bosnia and Herzegovina",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "BIH.jpg"
    },
    {
        code: "BIZ",
        label: "Belize",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "BIZ.jpg"
    },
    {
        code: "BLR",
        label: "Belarus",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "BLR.jpg"
    },
    {
        code: "BOL",
        label: "Bolivia",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "BOL.jpg"
    },
    {
        code: "BOT",
        label: "Botswana",
        Region: "Africa",
        SubRegion: "South",
        MemberType: "country",
        flag: "BOT.jpg"
    },
    {
        code: "BRA",
        label: "Brazil",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "BRA.jpg"
    },
    {
        code: "BRN",
        label: "Bahrain",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "BRN.jpg"
    },
    {
        code: "BRU",
        label: "Brunei Darussalam",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "BRU.jpg"
    },
    {
        code: "BUL",
        label: "Bulgaria",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "BUL.jpg"
    },
    {
        code: "BUR",
        label: "Burkina Faso",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "BUR.jpg"
    },
    {
        code: "CAF",
        label: "Central African Republic",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "CAF.jpg"
    },
    {
        code: "CAM",
        label: "Cambodia",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "CAM.jpg"
    },
    {
        code: "CAN",
        label: "Canada",
        Region: "America",
        SubRegion: "North",
        MemberType: "country",
        flag: "CAN.jpg"
    },
    {
        code: "CAY",
        label: "Cayman Islands",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "CAY.jpg"
    },
    {
        code: "CGO",
        label: "Congo",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "CGO.jpg"
    },
    {
        code: "CHA",
        label: "Chad",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "CHA.jpg"
    },
    {
        code: "CHI",
        label: "Chile",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "CHI.jpg"
    },
    {
        code: "CHN",
        label: "People's Republic of China",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "CHN.jpg"
    },
    {
        code: "CIV",
        label: "Côte d'Ivoire",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "CIV.jpg"
    },
    {
        code: "CMR",
        label: "Cameroon",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "CMR.jpg"
    },
    {
        code: "COD",
        label: "Democratic Republic of the Congo",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "COD.jpg"
    },
    {
        code: "COK",
        label: "Cook Islands",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "COK.jpg"
    },
    {
        code: "COL",
        label: "Colombia",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "COL.jpg"
    },
    {
        code: "COM",
        label: "Comoros",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "COM.jpg"
    },
    {
        code: "CPV",
        label: "Cape Verde",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "CPV.jpg"
    },
    {
        code: "CRC",
        label: "Costa Rica",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "CRC.jpg"
    },
    {
        code: "CRO",
        label: "Croatia",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "CRO.jpg"
    },
    {
        code: "CUB",
        label: "Cuba",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "CUB.jpg"
    },
    {
        code: "CUW",
        label: "Curaçao",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "Default.png"
    },
    {
        code: "CYP",
        label: "Cyprus",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "CYP.jpg"
    },
    {
        code: "CZE",
        label: "Czechia",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "CZE.jpg"
    },
    {
        code: "DEN",
        label: "Denmark",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "DEN.jpg"
    },
    {
        code: "DJI",
        label: "Djibouti",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "DJI.jpg"
    },
    {
        code: "DMA",
        label: "Dominica",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "DMA.jpg"
    },
    {
        code: "DOM",
        label: "Dominican Republic",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "DOM.jpg"
    },
    {
        code: "ECU",
        label: "Ecuador",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "ECU.jpg"
    },
    {
        code: "EGY",
        label: "Egypt",
        Region: "Africa",
        SubRegion: "North",
        MemberType: "country",
        flag: "EGY.jpg"
    },
    {
        code: "ENG",
        label: "England",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: ""
    },
    {
        code: "ERI",
        label: "Eritrea",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "ERI.jpg"
    },
    {
        code: "ESA",
        label: "El Salvador",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "ESA.jpg"
    },
    {
        code: "ESP",
        label: "Spain",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "ESP.jpg"
    },
    {
        code: "EST",
        label: "Estonia",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "EST.jpg"
    },
    {
        code: "ETH",
        label: "Ethiopia",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "ETH.jpg"
    },
    {
        code: "FIJ",
        label: "Fiji",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "FIJ.jpg"
    },
    {
        code: "FIN",
        label: "Finland",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "FIN.jpg"
    },
    {
        code: "FRA",
        label: "France",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "FRA.jpg"
    },
    {
        code: "FRO",
        label: "Faroe Islands",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "FRO.jpg"
    },
    {
        code: "FSM",
        label: "Micronesia",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "FSM.jpg"
    },
    {
        code: "GAB",
        label: "Gabon",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "GAB.jpg"
    },
    {
        code: "GAM",
        label: "Gambia",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "GAM.jpg"
    },
    {
        code: "GBR",
        label: "Great Britain",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "GBR.jpg"
    },
    {
        code: "GBS",
        label: "Guinea-Bissau",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "GBS.jpg"
    },
    {
        code: "GEO",
        label: "Georgia",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "GEO.jpg"
    },
    {
        code: "GEQ",
        label: "Equatorial Guinea",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "GEQ.jpg"
    },
    {
        code: "GER",
        label: "Germany",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "GER.jpg"
    },
    {
        code: "GHA",
        label: "Ghana",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "GHA.jpg"
    },
    {
        code: "GLP",
        label: "Guadeloupe",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "Default.png"
    },
    {
        code: "GRE",
        label: "Greece",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "GRE.jpg"
    },
    {
        code: "GRN",
        label: "Grenada",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "GRN.jpg"
    },
    {
        code: "GUA",
        label: "Guatemala",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "GUA.jpg"
    },
    {
        code: "GUF",
        label: "French Guiana",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "Default.png"
    },
    {
        code: "GUI",
        label: "Guinea",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "GUI.jpg"
    },
    {
        code: "GUM",
        label: "Guam",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "GUM.jpg"
    },
    {
        code: "GUY",
        label: "Guyana",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "GUY.jpg"
    },
    {
        code: "HAI",
        label: "Haiti",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "HAI.jpg"
    },
    {
        code: "HKG",
        label: "Hong Kong, China",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "HKG.jpg"
    },
    {
        code: "HON",
        label: "Honduras",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "HON.jpg"
    },
    {
        code: "HUN",
        label: "Hungary",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "HUN.jpg"
    },
    {
        code: "INA",
        label: "Indonesia",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "INA.jpg"
    },
    {
        code: "IND",
        label: "India",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "IND.jpg"
    },
    {
        code: "IOM",
        label: "Isle of Man",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "Default.png"
    },
    {
        code: "IPA",
        label: "Individual Paralympic Athletes",
        Region: "-",
        SubRegion: "-",
        MemberType: "construct",
        flag: "Default.png"
    },
    {
        code: "IRI",
        label: "Islamic Republic of Iran",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "IRI.jpg"
    },
    {
        code: "IRL",
        label: "Ireland",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "IRL.jpg"
    },
    {
        code: "IRQ",
        label: "Iraq",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "IRQ.jpg"
    },
    {
        code: "ISL",
        label: "Iceland",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "ISL.jpg"
    },
    {
        code: "ISR",
        label: "Israel",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "ISR.jpg"
    },
    {
        code: "ISV",
        label: "U.S. Virgin Islands",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "ISV.jpg"
    },
    {
        code: "ITA",
        label: "Italy",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "ITA.jpg"
    },
    {
        code: "IVB",
        label: "British Virgin Islands",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "IVB.jpg"
    },
    {
        code: "JAM",
        label: "Jamaica",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "JAM.jpg"
    },
    {
        code: "JOR",
        label: "Jordan",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "JOR.jpg"
    },
    {
        code: "JPN",
        label: "Japan",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "JPN.jpg"
    },
    {
        code: "KAZ",
        label: "Kazakhstan",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "KAZ.jpg"
    },
    {
        code: "KEN",
        label: "Kenya",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "KEN.jpg"
    },
    {
        code: "KGZ",
        label: "Kyrgyzstan",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "KGZ.jpg"
    },
    {
        code: "KIR",
        label: "Kiribati",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "KIR.jpg"
    },
    {
        code: "KOR",
        label: "Republic of Korea",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "KOR.jpg"
    },
    {
        code: "KOS",
        label: "Kosovo",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "KOS.jpg"
    },
    {
        code: "KSA",
        label: "Saudi Arabia",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "KSA.jpg"
    },
    {
        code: "KUW",
        label: "Kuwait",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "KUW.jpg"
    },
    {
        code: "LAO",
        label: "Lao People's Democratic Republic",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "LAO.jpg"
    },
    {
        code: "LAT",
        label: "Latvia",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "LAT.jpg"
    },
    {
        code: "LBA",
        label: "Libya",
        Region: "Africa",
        SubRegion: "North",
        MemberType: "country",
        flag: "LBA.jpg"
    },
    {
        code: "LBN",
        label: "Lebanon",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "LBN.jpg"
    },
    {
        code: "LBR",
        label: "Liberia",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "LBR.jpg"
    },
    {
        code: "LCA",
        label: "Saint Lucia",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "LCA.jpg"
    },
    {
        code: "LES",
        label: "Lesotho",
        Region: "Africa",
        SubRegion: "South",
        MemberType: "country",
        flag: "LES.jpg"
    },
    {
        code: "LIE",
        label: "Liechtenstein",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "LIE.jpg"
    },
    {
        code: "LTU",
        label: "Lithuania",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "LTU.jpg"
    },
    {
        code: "LUX",
        label: "Luxembourg",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "LUX.jpg"
    },
    {
        code: "MAC",
        label: "Macao, China",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "MAC.jpg"
    },
    {
        code: "MAD",
        label: "Madagascar",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "MAD.jpg"
    },
    {
        code: "MAR",
        label: "Morocco",
        Region: "Africa",
        SubRegion: "North",
        MemberType: "country",
        flag: "MAR.jpg"
    },
    {
        code: "MAS",
        label: "Malaysia",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "MAS.jpg"
    },
    {
        code: "MAW",
        label: "Malawi",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "MAW.jpg"
    },
    {
        code: "MDA",
        label: "Republic of Moldova",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "MDA.jpg"
    },
    {
        code: "MDV",
        label: "Maldives",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "MDV.jpg"
    },
    {
        code: "MEX",
        label: "Mexico",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "MEX.jpg"
    },
    {
        code: "MGL",
        label: "Mongolia",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "MGL.jpg"
    },
    {
        code: "MHL",
        label: "Marshall Islands",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "MHL.jpg"
    },
    {
        code: "MKD",
        label: "North Macedonia",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "MKD.jpg"
    },
    {
        code: "MLI",
        label: "Mali",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "MLI.jpg"
    },
    {
        code: "MLT",
        label: "Malta",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "MLT.jpg"
    },
    {
        code: "MNE",
        label: "Montenegro",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "MNE.jpg"
    },
    {
        code: "MON",
        label: "Monaco",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "MON.jpg"
    },
    {
        code: "MOZ",
        label: "Mozambique",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "MOZ.jpg"
    },
    {
        code: "MRI",
        label: "Mauritius",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "MRI.jpg"
    },
    {
        code: "MTN",
        label: "Mauritania",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "MTN.jpg"
    },
    {
        code: "MTQ",
        label: "Martinique",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: ""
    },
    {
        code: "MYA",
        label: "Myanmar",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "MYA.jpg"
    },
    {
        code: "NAM",
        label: "Namibia",
        Region: "Africa",
        SubRegion: "South",
        MemberType: "country",
        flag: "NAM.jpg"
    },
    {
        code: "NCA",
        label: "Nicaragua",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "NCA.jpg"
    },
    {
        code: "NCL",
        label: "New Caledonia",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: ""
    },
    {
        code: "NED",
        label: "Netherlands",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "NED.jpg"
    },
    {
        code: "NEP",
        label: "Nepal",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "NEP.jpg"
    },
    {
        code: "NGR",
        label: "Nigeria",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "NGR.jpg"
    },
    {
        code: "NIG",
        label: "Niger",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "NIG.jpg"
    },
    {
        code: "NOR",
        label: "Norway",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "NOR.jpg"
    },
    {
        code: "NPA",
        label: "Neutral Para Athlete",
        Region: "-",
        SubRegion: "-",
        MemberType: "construct",
        flag: ""
    },
    {
        code: "NRU",
        label: "Nauru",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "NRU.jpg"
    },
    {
        code: "NZL",
        label: "New Zealand",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "NZL.jpg"
    },
    {
        code: "OMA",
        label: "Oman",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "OMA.jpg"
    },
    {
        code: "PAK",
        label: "Pakistan",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "PAK.jpg"
    },
    {
        code: "PAN",
        label: "Panama",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "PAN.jpg"
    },
    {
        code: "PAR",
        label: "Paraguay",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "PAR.jpg"
    },
    {
        code: "PER",
        label: "Peru",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "PER.jpg"
    },
    {
        code: "PHI",
        label: "Philippines",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "PHI.jpg"
    },
    {
        code: "PLE",
        label: "Palestine",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "PLE.jpg"
    },
    {
        code: "PLW",
        label: "Palau",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "PLW.jpg"
    },
    {
        code: "PNG",
        label: "Papua New Guinea",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "PNG.jpg"
    },
    {
        code: "POL",
        label: "Poland",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "POL.jpg"
    },
    {
        code: "POR",
        label: "Portugal",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "POR.jpg"
    },
    {
        code: "PRK",
        label: "Democratic People's Republic of Korea",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "PRK.jpg"
    },
    {
        code: "PUR",
        label: "Puerto Rico",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "PUR.jpg"
    },
    {
        code: "PYF",
        label: "French Polynesia",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: ""
    },
    {
        code: "QAT",
        label: "Qatar",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "QAT.jpg"
    },
    {
        code: "ROU",
        label: "Romania",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "ROU.jpg"
    },
    {
        code: "RPC",
        label: "RPC",
        Region: "-",
        SubRegion: "-",
        MemberType: "construct",
        flag: "RPC.jpg"
    },
    {
        code: "RPT",
        label: "Refugee Para(lympic) Team",
        Region: "-",
        SubRegion: "-",
        MemberType: "construct",
        flag: ""
    },
    {
        code: "RSA",
        label: "South Africa",
        Region: "Africa",
        SubRegion: "South",
        MemberType: "country",
        flag: "RSA.jpg"
    },
    {
        code: "RUS",
        label: "Russian Federation",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "RUS.png"
    },
    {
        code: "RWA",
        label: "Rwanda",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "RWA.jpg"
    },
    {
        code: "SAM",
        label: "Samoa",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "SAM.jpg"
    },
    {
        code: "SCO",
        label: "Scotland",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: ""
    },
    {
        code: "SEN",
        label: "Senegal",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "SEN.jpg"
    },
    {
        code: "SEY",
        label: "Seychelles",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "SEY.jpg"
    },
    {
        code: "SGP",
        label: "Singapore",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "SGP.jpg"
    },
    {
        code: "SKN",
        label: "Saint Kitts and Nevis",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "SKN.jpg"
    },
    {
        code: "SLE",
        label: "Sierra Leone",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "SLE.jpg"
    },
    {
        code: "SLO",
        label: "Slovenia",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "SLO.jpg"
    },
    {
        code: "SMR",
        label: "San Marino",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "SMR.jpg"
    },
    {
        code: "SOL",
        label: "Solomon Islands",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "SOL.jpg"
    },
    {
        code: "SOM",
        label: "Somalia",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "SOM.jpg"
    },
    {
        code: "SRB",
        label: "Serbia",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "SRB.jpg"
    },
    {
        code: "SRI",
        label: "Sri Lanka",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "SRI.jpg"
    },
    {
        code: "SSD",
        label: "South Sudan",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "SSD.jpg"
    },
    {
        code: "STP",
        label: "Sao Tome and Principe",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "STP.jpg"
    },
    {
        code: "SUD",
        label: "Sudan",
        Region: "Africa",
        SubRegion: "North",
        MemberType: "country",
        flag: "SUD.jpg"
    },
    {
        code: "SUI",
        label: "Switzerland",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "SUI.jpg"
    },
    {
        code: "SUR",
        label: "Suriname",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "SUR.jpg"
    },
    {
        code: "SVK",
        label: "Slovakia",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "SVK.jpg"
    },
    {
        code: "SWE",
        label: "Sweden",
        Region: "Europe",
        SubRegion: "North, South, West",
        MemberType: "country",
        flag: "SWE.jpg"
    },
    {
        code: "SWZ",
        label: "Eswatini",
        Region: "Africa",
        SubRegion: "South",
        MemberType: "country",
        flag: "SWZ.jpg"
    },
    {
        code: "SYR",
        label: "Syrian Arab Republic",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "SYR.jpg"
    },
    {
        code: "TAN",
        label: "Tanzania",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "TAN.jpg"
    },
    {
        code: "TGA",
        label: "Tonga",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "TGA.jpg"
    },
    {
        code: "THA",
        label: "Thailand",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "THA.jpg"
    },
    {
        code: "TJK",
        label: "Tajikistan",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "TJK.jpg"
    },
    {
        code: "TKM",
        label: "Turkmenistan",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "TKM.jpg"
    },
    {
        code: "TLS",
        label: "Timor-Leste",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "TLS.jpg"
    },
    {
        code: "TOG",
        label: "Togo",
        Region: "Africa",
        SubRegion: "West, Central",
        MemberType: "country",
        flag: "TOG.jpg"
    },
    {
        code: "TPE",
        label: "Chinese Taipei",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "TPE.jpg"
    },
    {
        code: "TTO",
        label: "Trinidad and Tobago",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "TTO.jpg"
    },
    {
        code: "TUN",
        label: "Tunisia",
        Region: "Africa",
        SubRegion: "North",
        MemberType: "country",
        flag: "TUN.jpg"
    },
    {
        code: "TUR",
        label: "Türkiye",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "TUR.jpg"
    },
    {
        code: "TUV",
        label: "Tuvalu",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "TUV.jpg"
    },
    {
        code: "UAE",
        label: "United Arab Emirates",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "UAE.jpg"
    },
    {
        code: "UGA",
        label: "Uganda",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "UGA.jpg"
    },
    {
        code: "UKR",
        label: "Ukraine",
        Region: "Europe",
        SubRegion: "East",
        MemberType: "country",
        flag: "UKR.jpg"
    },
    {
        code: "URU",
        label: "Uruguay",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "URU.jpg"
    },
    {
        code: "USA",
        label: "United States of America",
        Region: "America",
        SubRegion: "North",
        MemberType: "country",
        flag: "USA.jpg"
    },
    {
        code: "UZB",
        label: "Uzbekistan",
        Region: "Asia",
        SubRegion: "Central, East",
        MemberType: "country",
        flag: "UZB.jpg"
    },
    {
        code: "VAN",
        label: "Vanuatu",
        Region: "Oceania",
        SubRegion: "Oceania",
        MemberType: "country",
        flag: "VAN.jpg"
    },
    {
        code: "VEN",
        label: "Venezuela",
        Region: "America",
        SubRegion: "South",
        MemberType: "country",
        flag: "VEN.jpg"
    },
    {
        code: "VIE",
        label: "Vietnam",
        Region: "Asia",
        SubRegion: "South, South-East",
        MemberType: "country",
        flag: "VIE.jpg"
    },
    {
        code: "VIN",
        label: "Saint Vincent and the Grenadines",
        Region: "America",
        SubRegion: "Carribean, Central",
        MemberType: "country",
        flag: "VIN.jpg"
    },
    {
        code: "YEM",
        label: "Yemen",
        Region: "Asia",
        SubRegion: "West",
        MemberType: "country",
        flag: "YEM.jpg"
    },
    {
        code: "ZAM",
        label: "Zambia",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "ZAM.jpg"
    },
    {
        code: "ZIM",
        label: "Zimbabwe",
        Region: "Africa",
        SubRegion: "East",
        MemberType: "country",
        flag: "ZIM.jpg"
    }
];

export const CountriesOLD = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZE', label: 'Azerbaijan', phone: '994' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BRA', label: 'Brazil', phone: '55', flag: 'BR' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
  },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  {
    code: 'DOM',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
  },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GBR', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'INA', label: 'India', phone: '91' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KAZ', label: 'Kazakhstan', phone: '7' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  {
    code: 'MDA',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  { code: 'TD', label: 'Chad', phone: '235' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'THA', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    code: 'TW',
    label: 'Taiwan, Republic of China',
    phone: '886',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  { code: 'UKR', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
  },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];