import * as React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Admin,
  Resource,
  ListGuesser,
  defaultTheme,
  AppBar,
  Layout,
  Menu,
  CustomRoutes,
  useTranslate,
  Button,
} from "react-admin";
import { Route } from "react-router-dom";
import { Fragment } from "react";
import ParseAuth from "./parseAuth";
import ParseClient from "./parseClient";
import addUploadFeature from "./addUploadFeature";
import indigo from "@mui/material/colors/indigo";
import pink from "@mui/material/colors/pink";
import red from "@mui/material/colors/red";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import Typography from "@mui/material/Typography";
import { Dashboard } from "./Dashboard";
import { Run } from "./Run";
import { Board } from "./Board";
import { Display } from "./Display";
import { Referee } from "./Referee";
import PrintIcon from "@mui/icons-material/Print";
// import Logo from './assets/domi.png';
import Parse from "parse";
import { PostList, PostCreate, PostEdit } from "./posts";
import {
  CompetitionCreate,
  CompetitionEdit,
  CompetitionList,
} from "./Competitions";
import { LifterCreate, LifterEdit, LifterList } from "./Lifters";
import MyLoginPage from "./MyLoginPage";
import { WeightIn } from "./WeightIn";
import { PaperWork } from "./PaperWork";
import AwardDivisions, { AwardList2 } from "./AwardDivisions";
import localForageDataProvider from "ra-data-local-forage";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./i18n/en";
//scp -r build/* root@45.55.41.53:/var/parse-server-example/public/wppo/
const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === "fr") {
      return import("./i18n/fr").then((messages) => messages.default);
    }
    if (locale === "pt") {
      return import("./i18n/pt").then((messages) => messages.default);
    }

    // Always fallback on english
    return englishMessages;
  },
  "en",
  [
    { locale: "en", name: "English" },
    { locale: "fr", name: "Français" },
    { locale: "pt", name: "Portuguese" },
  ],
);

const parseConfig = {
  URL: "https://wppo.freiesland.com/parapower",
  JAVASCRIPT_KEY: null,
  APP_ID: "ParaPowerCr0b51",
  MASTER_KEY: "ParaPowerm4st3rk3y",
};
const authProvider = ParseAuth(parseConfig);
const dataProviderB = ParseClient(parseConfig);
const uploadCapableDataProvider = addUploadFeature(dataProviderB);

const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      light: "#9f0808",
      main: "#5c0000",
      dark: "#5c0000",
      contrastText: "#fff",
    },
    secondary: { main: "#9f0808" },
    error: red,
    white: { main: "#FFFFFF" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  sidebar: {
    width: 150, // The default value is 240
    closedWidth: 50, // The default value is 55
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          backgroundColor: "White",
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#9f0808",
            color: "#FFF",
          },
        },
      },
    },
  },
};

const MyMenu = () => {
  if (localStorage.getItem("currentCompetition"))
    return (
      <Menu>
        <small
          style={{
            padding: 20,
            fontWeight: "bold",
          }}
        >
          {localStorage.getItem("currentCompetitionName")
            ? `Currently on: ${localStorage.getItem("currentCompetitionName")}`
            : `You need to select one competition on dashboard`}
        </small>
        <Menu.DashboardItem />
        {/*<Menu.ResourceItem name="competitions" />*/}
        <Menu.ResourceItem name="lifters" />
        <Menu.ResourceItem name="teams" />
        {/*<Menu.ResourceItem name="weight_in" />*/}
        <Menu.ResourceItem name="platforms" />
        <Menu.ResourceItem name="paper_work" />
        <Menu.ResourceItem name="award_divisions" />
        <Menu.ResourceItem name="results" />
      </Menu>
    );

  return (
    <Menu>
      <small
        style={{
          padding: 20,
          fontWeight: "bold",
        }}
      >
        Hola, {/*user.nombre_completo*/}
      </small>
      <Menu.DashboardItem />
      {/*<Menu.ResourceItem name="competitions" />*/}
      {/*<Menu.ResourceItem name="lifters" />
        <Menu.ResourceItem name="teams" />
        <Menu.ResourceItem name="weight_in" />
        <Menu.ResourceItem name="platforms" />
        <Menu.ResourceItem name="paper_work" />
        <Menu.ResourceItem name="award_divisions" />*/}
    </Menu>
  );
};

const MyAppBar = (props) => {
  const translate = useTranslate();
  return (
    <AppBar {...props} color="primary">
      <Typography style={{ flex: 1 }}>T&S WPPO</Typography>
      <span style={{ flex: 1 }} />
      {/*localStorage.getItem("offline") ? (
        <>
          <Button
            color="secondary"
            onClick={() => {
              localStorage.removeItem("offline");
              window.location.reload();
            }}
            variant="contained"
            label="pos.dashboard.welcome.ra_button_offline"
          />
        </>
      ) : (
        <>
          <Button
            color="secondary"
            onClick={() => {
              localStorage.setItem("offline", true);
              window.location.reload();
            }}
            variant="contained"
            label="pos.dashboard.welcome.ra_button_online"
          />
        </>
      )*/}
    </AppBar>
  );
};

const MyLayout = (props) => (
  <Layout appBar={MyAppBar} {...props} menu={MyMenu} />
);

const App = () => {
  const [dataProvider, setDataProvider] = React.useState(null);
  const translate = useTranslate();
  React.useEffect(() => {
    async function startDataProvider() {
      const localForageProvider = await localForageDataProvider();
      setDataProvider(localForageProvider);
    }

    if (dataProvider === null) {
      startDataProvider();
    }
  }, [dataProvider]);

  // hide the admin until the data provider is ready
  if (!dataProvider) return <p>Loading...</p>;

  return (
    <Admin
      loginPage={MyLoginPage}
      i18nProvider={i18nProvider}
      dataProvider={
        localStorage.getItem("offline")
          ? dataProvider
          : uploadCapableDataProvider
      }
      authProvider={authProvider}
      theme={theme}
      dashboard={Dashboard}
      layout={MyLayout}
    >
      <Resource
        options={{ label: translate("pos.menu.sessions") }}
        name="platforms"
        list={PostList}
        edit={PostEdit}
        create={PostCreate}
      />
      <Resource
        options={{ label: translate("pos.menu.competitions") }}
        name="competitions"
        list={CompetitionList}
        create={CompetitionCreate}
        edit={CompetitionEdit}
        icon={EventIcon}
      />
      <Resource
        options={{ label: translate("pos.menu.athletes") }}
        name="lifters"
        list={LifterList}
        create={LifterCreate}
        edit={LifterEdit}
        icon={GroupsIcon}
      />
      <Resource
        options={{ label: translate("pos.menu.teams") }}
        name="teams"
        list={ListGuesser}
      />
      {/*<Resource name="weight_in" list={WeightIn} icon={MonitorWeightIcon}  />*/}
      <Resource
        options={{ label: translate("pos.menu.paperwork") }}
        name="paper_work"
        options={{ label: "Paperwork" }}
        list={PaperWork}
        icon={PrintIcon}
      />
      <Resource
        options={{ label: translate("pos.menu.award_divisions") }}
        name="award_divisions"
        {...AwardDivisions}
      />
      <Resource
        options={{ label: translate("pos.menu.results") }}
        name="results"
        list={AwardList2}
      />
      <CustomRoutes noLayout>
        <Route path="/run/:platform" element={<Run />} noLayout />
        <Route path="/board/:platform" element={<Board />} noLayout />
        <Route path="/display/:platform" element={<Display />} noLayout />
        <Route
          path="/rreferee/:platform"
          element={<Referee position="r" />}
          noLayout
        />
        <Route
          path="/lreferee/:platform"
          element={<Referee position="l" />}
          noLayout
        />
        <Route
          path="/creferee/:platform"
          element={<Referee position="c" />}
          noLayout
        />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
