import React from "react";
import { useState, useRef } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  useRecordContext,
  Loading,
  useGetList,
  useList,
  ListContextProvider,
  Button,
  useDataProvider,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PrintIcon from "@mui/icons-material/Print";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RunField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/run/${record.id}`} target="_blank">
      Run
    </a>
  );
};

const BoardField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/board/${record.id}`} target="_blank">
      Run
    </a>
  );
};

const DisplayField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/display/${record.id}`} target="_blank">
      Run
    </a>
  );
};

const RefereeField = () => {
  const record = useRecordContext();
  return (
    <div>
      <a href={`#/creferee/${record.id}`} target="_blank">
        HR
      </a>
      <br />
      <a href={`#/lreferee/${record.id}`} target="_blank">
        LR
      </a>
      <br />
      <a href={`#/rreferee/${record.id}`} target="_blank">
        RR
      </a>
    </div>
  );
};

const postFilters = [<TextInput label="name" source="name" alwaysOn />];

export const PaperWork = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState(false);
  const [competition, setCompetition] = React.useState(false);
  const [lifters, setLifters] = React.useState([]);
  const [dlgTitle, setDlgTitle] = React.useState(false);
  const [canvasImg, setCanvasImg] = useState(null);
  const exportRef = useRef();
  const dataProvider = useDataProvider();

  const {
    data: teams,
    total2,
    isLoading2,
    error2,
  } = useGetList("teams", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const handleClickOpen = async (title, record) => {
    setDlgTitle(title);
    setOpen(true);
    setRecord(record);
    /*Traigo a los atletas de la sesion*/
    const { data: lifters } = await dataProvider.getList("lifters", {
      filter: {
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
        platform: record.id,
      },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "fullname", order: "ASC" },
    });
    console.log(lifters);
    setLifters(lifters);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function getCompetition() {
    const { data: recordCompetition } = await dataProvider.getOne(
      "competitions",
      { id: localStorage.getItem("currentCompetition") },
    );
    setCompetition(recordCompetition);
  }

  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    } else {
      getCompetition();
    }
  }, []);

  const imprimir = () => {
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(`<style type="text/css" media="print" />
        body {font-size: 8pt; font-family:'Arial','Times New Roman',Times,serif;}
        table { page-break-inside: avoid;page-break-after:auto }
        tr    { page-break-inside:avoid; page-break-after:auto }
        td    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important; font-family:'Arial','Times New Roman',Times,serif; }
        thead { display:table-header-group }
        tfoot { display:table-footer-group }</style>`);
    pri.document.write(document.getElementById("toprint").innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const ACButton = () => {
    const record = useRecordContext();
    return (
      <Button
        onClick={() => handleClickOpen("Attempt Cards", record)}
        label="Attempt Cards"
      />
    );
  };
  const STButton = () => {
    const record = useRecordContext();
    return (
      <Button
        onClick={() => handleClickOpen("Start List", record)}
        label="Start List"
      />
    );
  };

  const KCHButton = () => {
    const record = useRecordContext();
    return (
      <Button
        onClick={() => handleClickOpen("Kit Check", record)}
        label="Kit Check"
      />
    );
  };

  const WCButton = () => {
    const record = useRecordContext();
    return (
      <Button
        onClick={() => handleClickOpen("Weigh in", record)}
        label="Weigh in"
      />
    );
  };

  return (
    <>
      <List
        perPage={1000}
        filter={{
          competition: localStorage.getItem("currentCompetition")
            ? localStorage.getItem("currentCompetition")
            : null,
        }}
        filters={postFilters}
        resource={"platforms"}
      >
        <Datagrid rowClick="false">
          <TextField source="id" />
          <TextField source="name" />
          <WCButton />
          <ACButton />
          <STButton />
          <KCHButton />
        </Datagrid>
      </List>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ display: "flex" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
              Close
            </IconButton>
            <h3 style={{ flex: 1, textAlign: "center" }}>{dlgTitle}</h3>
            <IconButton
              autoFocus
              color="inherit"
              onClick={() => {
                imprimir();
              }}
            >
              <PrintIcon />
              Print
            </IconButton>
          </Toolbar>
        </AppBar>
        <iframe
          title="pdf"
          id="ifmcontentstoprint"
          className="pdfIframe"
        ></iframe>
        {competition && lifters.length && dlgTitle === "Attempt Cards" ? (
          <Box id="toprint" ref={exportRef}>
            {lifters
              .sort((a, b) => {
                if (a.first_attempt === b.first_attempt) {
                  return b.lot_number < a.lot_number ? -1 : 1;
                } else {
                  return a.first_attempt < b.first_attempt ? -1 : 1;
                }
              })
              .map((lifter) => (
                <Table
                  className="myFormat"
                  size="small"
                  style={{
                    width: "666px",
                    border: "solid",
                    borderWidth: 1,
                    borderCollapse: "collapse",
                    marginBottom: 2,
                  }}
                >
                  <thead>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                        colspan={16}
                      >
                        Attempt Card
                        <br />
                        {competition ? competition.meet_name : null}
                      </TableCell>
                      {/*competition && competition.pictures && competition.pictures[0] ? <img src={competition.pictures[0].src} height="80" /> : null*/}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontWeight: "bold",
                        }}
                        colspan={2}
                      >
                        Lot
                      </TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                        colspan={1}
                      >
                        {lifter.lot_number}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontWeight: "bold",
                        }}
                        colspan={2}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 8,
                          whiteSpace: "nowrap",
                        }}
                        colspan={4}
                      >
                        {lifter.fullname}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontWeight: "bold",
                        }}
                      >
                        Country/Team
                      </TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                        colspan={2}
                      >
                        {lifter.country} -{" "}
                        {teams &&
                        teams.length &&
                        teams.find((item) => item.id === lifter.team)
                          ? teams.find((item) => item.id === lifter.team).name
                          : null}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontWeight: "bold",
                        }}
                        colspan={1}
                      >
                        DoB
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          whiteSpace: "nowrap",
                        }}
                        colspan={3}
                      >
                        {lifter.date_of_birth}
                      </TableCell>
                    </TableRow>
                  </thead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontWeight: "bold",
                        }}
                        colspan={2}
                      >
                        Cat/Grp.
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          minWidth: 50,
                        }}
                        colspan={1}
                      >
                        {lifter.weight_class}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontWeight: "bold",
                        }}
                        colspan={2}
                      >
                        Bodyweight
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          minWidth: 50,
                        }}
                        colspan={2}
                      >
                        {lifter.bodyweight}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontWeight: "bold",
                        }}
                        colspan={2}
                      >
                        Rack Height
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          minWidth: 50,
                        }}
                        colspan={2}
                      >
                        {lifter.rack_height}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontWeight: "bold",
                        }}
                        colspan={2}
                      >
                        Age Group
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          minWidth: 50,
                        }}
                        colspan={3}
                      >
                        {lifter.divisions && lifter.divisions.length
                          ? lifter.divisions
                              .map((divission) => divission.name)
                              .join(", ")
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: "#DDDDDD" }}>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                        colspan={4}
                      >
                        1st Attempt
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                        colspan={4}
                      >
                        2st Attempt
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                        colspan={4}
                      >
                        3st Attempt
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                        colspan={4}
                      >
                        Power Lift
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Weigh-in
                        </div>
                        <small>
                          {lifter.first_attempt ? lifter.first_attempt : ""}
                        </small>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Automatic / Declared
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Automatic / Declared
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        ></div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Signature
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Signature
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Signature
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Signature
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{
                            fontSize: 8,
                            height: 20,
                            marginTop: "-5px",
                            fontWeight: "bold",
                          }}
                        >
                          1st Change
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                        rowspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        ></div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{
                            fontSize: 8,
                            height: 20,
                            marginTop: "-5px",
                            fontWeight: "bold",
                          }}
                        >
                          1st Change
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{
                            fontSize: 8,
                            height: 20,
                            marginTop: "-5px",
                            fontWeight: "bold",
                          }}
                        >
                          Result
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Signature
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Signature
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={2}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Best
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={2}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Total
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                        rowspan={2}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        ></div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{
                            fontSize: 8,
                            height: 20,
                            marginTop: "-5px",
                            fontWeight: "bold",
                          }}
                        >
                          2nd Change
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{
                            fontSize: 8,
                            height: 20,
                            marginTop: "-5px",
                            fontWeight: "bold",
                          }}
                        >
                          Rank
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={4}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Signature
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={2}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Best
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          textAlign: "center",
                        }}
                        colspan={2}
                      >
                        <div
                          style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                        >
                          Total
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
          </Box>
        ) : null}

        {lifters.length && dlgTitle === "Start List" ? (
          <Box id="toprint" ref={exportRef}>
            <div style={{ textAlign: "center" }}>
              {competition &&
              competition.pictures &&
              competition.pictures[0] ? (
                <img src={competition.pictures[0].src} height="70" />
              ) : null}
              <h1 style={{ fontSize: 14 }}>Start List</h1>
            </div>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                      width: "15%",
                    }}
                  >
                    Competition
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.meet_name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition && competition.start_date
                      ? competition.start_date.split("-")[2] +
                        "/" +
                        competition.start_date.split("-")[1] +
                        "/" +
                        competition.start_date.split("-")[0]
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Bodyweight Category
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {record.name}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 2,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    Lot
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    SDMS
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    Country/Team
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    DoB
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    Age Group
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    Body Weight
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    Rack Height
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    colspan={4}
                  >
                    Attempts
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    Best Lift
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                    rowspan={2}
                  >
                    Rank
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    PL
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lifters
                  .sort((a, b) => {
                    if (a.first_attempt === b.first_attempt) {
                      return b.lot_number < a.lot_number ? -1 : 1;
                    } else {
                      return a.first_attempt < b.first_attempt ? -1 : 1;
                    }
                  })
                  .map((lifter) => (
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.lot_number}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.fullname}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.sdmsid}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.country} -{" "}
                        {teams &&
                        teams.length &&
                        teams.find((item) => item.id === lifter.team)
                          ? teams.find((item) => item.id === lifter.team).name
                          : null}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.date_of_birth}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.divisions && lifter.divisions.length
                          ? lifter.divisions
                              .map((divission) => divission.name)
                              .join(", ")
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.bodyweight}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.rack_height}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.first_attempt}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 8,
                        }}
                      >
                        ___
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 8,
                        }}
                      >
                        ___
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 8,
                        }}
                      >
                        ___
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 8,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 8,
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        ) : null}
        {lifters.length && dlgTitle === "Weigh in" ? (
          <Box id="toprint" ref={exportRef}>
            <div style={{ textAlign: "center" }}>
              {competition &&
              competition.pictures &&
              competition.pictures[0] ? (
                <img src={competition.pictures[0].src} height="70" />
              ) : null}
              <h1 style={{ fontSize: 14 }}>Weigh-in List</h1>
            </div>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                      width: "15%",
                    }}
                  >
                    Competition
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.meet_name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition && competition.start_date
                      ? competition.start_date.split("-")[2] +
                        "/" +
                        competition.start_date.split("-")[1] +
                        "/" +
                        competition.start_date.split("-")[0]
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Bodyweight Category
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {record.name}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 2,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Lot
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    SDMS
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Country/Team
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    DoB
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Body Weight
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Rack Height
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    First Attempt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lifters
                  .sort((a, b) => {
                    if (a.first_attempt === b.first_attempt) {
                      return b.lot_number < a.lot_number ? -1 : 1;
                    } else {
                      return a.first_attempt < b.first_attempt ? -1 : 1;
                    }
                  })
                  .map((lifter) => (
                    <TableRow>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.lot_number}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.fullname}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.sdmsid}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.country} -{" "}
                        {teams &&
                        teams.length &&
                        teams.find((item) => item.id === lifter.team)
                          ? teams.find((item) => item.id === lifter.team).name
                          : null}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.date_of_birth}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.bodyweight}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.rack_height}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 12,
                        }}
                      >
                        {lifter.first_attempt}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "none",
                borderWidth: 0,
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      borderWidth: 0,
                      padding: 4,
                      fontWeight: "bold",
                      fontSize: 10,
                    }}
                  >
                    Weigh-in Official Name:
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ) : null}
        {lifters.length && dlgTitle === "Kit Check" ? (
          <Box id="toprint" ref={exportRef}>
            <div style={{ textAlign: "center" }}>
              {competition &&
              competition.pictures &&
              competition.pictures[0] ? (
                <img src={competition.pictures[0].src} height="70" />
              ) : null}
              <h1 style={{ fontSize: 14 }}>Kit Check Inspection Sheet</h1>
            </div>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                      width: "15%",
                    }}
                  >
                    Competition
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.meet_name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition && competition.start_date
                      ? competition.start_date.split("-")[2] +
                        "/" +
                        competition.start_date.split("-")[1] +
                        "/" +
                        competition.start_date.split("-")[0]
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Bodyweight Category
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {record.name}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 2,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Lot
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Country/Team
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Lifting suit
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Shirt
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Other outfit
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Bra
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Socks
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Shoes
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Head item
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Wrist wraps
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Belt
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Own Bench Straps
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Plasters
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Music and lights
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Coach as spotter
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Codes of Exception
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lifters
                  .sort((a, b) => {
                    if (a.first_attempt === b.first_attempt) {
                      return b.lot_number < a.lot_number ? -1 : 1;
                    } else {
                      return a.first_attempt < b.first_attempt ? -1 : 1;
                    }
                  })
                  .map((lifter) => (
                    <TableRow>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      >
                        {lifter.lot_number}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          padding: 4,
                          fontSize: 8,
                        }}
                      >
                        {lifter.fullname}
                      </TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      >
                        {lifter.country} -{" "}
                        {teams &&
                        teams.length &&
                        teams.find((item) => item.id === lifter.team)
                          ? teams.find((item) => item.id === lifter.team).name
                          : null}
                      </TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      ></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        ) : null}
      </Dialog>
    </>
  );
};
